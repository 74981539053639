<!-- lxModal.vue -->
<template>
    <!-- 親から isOpen が true のときだけ表示されるモーダル -->
    <div class="lxModal" :class="[positionClass, { open: isOpen }]">
      <div class="lxModal-content">
        <!-- 閉じるボタン -->
        <div
          @click="onClickBack"
          :class="['backbtn', { 'disabled-backbtn': isAddingPoint }]"
        >
          <img src="../../assets/backbtn.png" alt="" class="smallicon" />
        </div>
  
        <div class="pointadd">
          <!-- この投稿のルクス -->
          <div class="pointadd--postlx">
            <h3>この投稿のルクス</h3>
            <p>
              <img src="../../assets/LX.png" alt="" class="smallicon" />
              <span v-html="whisper?.point"></span>
            </p>
          </div>
  
          <!-- ポイントを追加する欄 -->
          <div class="pointadd--addlx">
            <input
              v-model="localPointToAdd"
              type="number"
              min="0"
              placeholder="付与ルクスを入力してください"
            />
            <button
              :class="['point-button', whisper?.id, { 'disabled-button': isAddingPoint }]"
              @click="onClickAddPoint"
              :disabled="isAddingPoint"
            >
              <img src="../../assets/huyobtn.png" alt="" class="smallicon" />
            </button>
          </div>
  
          <!-- あなたのルクス (ログインユーザーの所持ルクス) -->
          <div class="pointadd--mylx">
            <h3>あなたのルクス</h3>
            <p>
              <span>{{ currentPoint }}</span>
              <button
                :class="['charge-button', { 'disabled-button': isAddingPoint }]"
                @click="onClickOpenChargeModal"
                :disabled="isAddingPoint"
              >
                <img src="../../assets/chargebtn.png" alt="" class="smallicon" />
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- メッセージモーダル -->
    <div class="MessageModal" v-if="isMessageModalOpen" @click.self="closeMessageModal">
        <div class="MessageModal_Body">
            <p>{{ message }}</p>
            <button class="btn_Modal" @click="closeMessageModal">OK</button>
        </div>
    </div>

    <!-- ルクスチャージモーダル コンポーネント -->
    <ChargeModal :modalOpen="modalOpen" :positionClass=positionClass @closeChargeModal="handleCloseChargeModal" />
  </template>
  
  <script>
    import { ref, watch, onMounted, computed } from 'vue'; 
    import { db, auth } from '../../main';
    import { doc, updateDoc, getDoc, setDoc, collection, getDocs } from 'firebase/firestore';
    import { onAuthStateChanged } from 'firebase/auth';
    import ChargeModal from '../chargeModal/chargeModal.vue';

    export default {
        name: 'lxModal',
        components: {
            ChargeModal
        },
        emits: ['close'],
        props: {
            // 親のvue側のモーダル表示/非表示フラグ
            isOpen: {
                type: Boolean,
                default: false
            },
            // 親から受け取る: モーダルの表示位置クラス
            positionClass: {
                type: String,
                default: 'absolute' // 特に指定がなければ absolute 扱い
            },
            // 親のvue側のwhisper
            whisper: {
                type: Object,
                default: () => ({})
            },
            // ユーザーの所持ルクス
            currentPoint: {
                type: Number,
                default: 0
            },
            // ルクスの初期値
            pointToAdd: {
                type: Number,
                default: 0
            }
        },

        setup(props, { emit }) {
            // ========== Reactive State ==========
            const localPointToAdd = ref(props.pointToAdd); 
            const isAddingPoint = ref(false);
            const userId = ref(null);
            const positionClass = computed(() => props.positionClass);

            // チャージモーダル用
            const modalOpen = ref(false);
            const selectedChargeWhisper = ref(null);

            // メッセージモーダル用
            const isMessageModalOpen = ref(false);
            const message = ref('');
            const messageCallback = ref(null);

            // ========== Watchers ==========
            // 親が pointToAdd を変化させたらローカルにも反映
            watch(
                () => props.pointToAdd,
                (newVal) => {
                    localPointToAdd.value = newVal;
                }
            );

            // ========== Lifecycle ==========
            onMounted(() => {
                // 子コンポーネント側で認証状態を確認し、userId をセット
                onAuthStateChanged(auth, (user) => {
                    if (user) {
                        userId.value = user.uid;
                    } else {
                        userId.value = null;
                    }
                });
            });

            // ========== Methods ==========
            // ✕ボタン押下時
            function onClickBack() {
                if (!isAddingPoint.value) {
                    emit('close'); // ルクス付与モーダルを閉じる
                }
            }

            // ルクス付与ボタン押下時
            async function onClickAddPoint() {
                if (!props.whisper?.id) return;

                const addedValue = parseInt(localPointToAdd.value, 10);
                if (isNaN(addedValue) || addedValue == 0) {
                    openMessageModal('付与ルクスを入力してください');
                    return;
                }
                if (addedValue < 0) {
                    openMessageModal('付与ルクスは1以上の数字を入力してください');
                    return;
                }
                if (!userId.value) {
                    if (confirm("ログインしてください")) {
                        router.push('/login');
                    }
                    return;
                }

                isAddingPoint.value = true;

                const docRefpost = doc(db, 'whispers', props.whisper.id);
                const docRefuser = doc(db, 'users', userId.value);

                try {
                    const [postDocSnap, userDocSnap] = await Promise.all([
                        getDoc(docRefpost),
                        getDoc(docRefuser)
                    ]);
                    if (!postDocSnap.exists() || !userDocSnap.exists()) {
                        throw new Error('投稿またはユーザー情報がありません');
                    }

                    // 所持ポイントチェック
                    const userCurrentPoints = userDocSnap.data().point || 0;
                    if (userCurrentPoints < addedValue) {
                        openMessageModal('所持ルクスが足りません');
                        return;
                    }

                    // 新しい投稿ポイント、ユーザーポイントを Firestore 更新
                    const newpostPoints = (postDocSnap.data().point || 0) + addedValue;
                    const newmyPoints = userCurrentPoints - addedValue;
                    await updateDoc(docRefpost, { point: newpostPoints });
                    await updateDoc(docRefuser, { point: newmyPoints });

                    // pointLog などログ記録
                    await addPointLog(addedValue, props.whisper.id, props.whisper.originid);

                    // addpointPost サブコレクションにリンクを追加
                    await addPostlink(props.whisper.id);

                    // 親画面に反映したい場合、子でも同じ whisper オブジェクト参照しているので
                    props.whisper.point = newpostPoints;

                    openMessageModal('ルクスを付与しました', () => {
                       emit('close'); // ルクス付与モーダルを閉じる
                   });
                } catch (e) {
                    console.error(e);
                    openMessageModal('ルクスの付与に失敗しました');
                } finally {
                    isAddingPoint.value = false;
                }
            }

            // ルクスチャージモーダルの表示
            function onClickOpenChargeModal() {
                selectedChargeWhisper.value = props.whisper;
                props.whisper.isModalOpen = false;
                modalOpen.value = true; // チャージモーダルを表示
            }

            // ルクスチャージモーダルの非表示
            function handleCloseChargeModal() {
                modalOpen.value = false;
                if (selectedChargeWhisper.value) {
                    props.whisper.isModalOpen = true; 
                    selectedChargeWhisper.value = null; 
                }
            }

            // pointLog / pointLogDay への書き込み
            async function addPointLog(addedValue, whisperId, originid) {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, '0');
                const day = String(today.getDate()).padStart(2, '0');
                const hours = String(today.getHours()).padStart(2, '0');
                const formattedDateForDoc = `${year}${month}${day}`;  // yyyyMMdd
                const formattedDateForDocTIME = `${year}${month}${day}${hours}`; // yyyyMMddHH

                // pointLog
                const docReflog = doc(db, 'pointLog', formattedDateForDoc);
                const subCollectionRef = collection(docReflog, 'whisperPoints');
                const newDocRef = doc(subCollectionRef, whisperId);

                const newDocSnap = await getDoc(newDocRef);
                if (newDocSnap.exists()) {
                    const existingPoints = newDocSnap.data().pointsAdded || 0;
                    await updateDoc(newDocRef, {
                        pointsAdded: existingPoints + addedValue,
                        originid
                    });
                } else {
                    await setDoc(newDocRef, {
                        pointsAdded: addedValue,
                        originid
                    });
                }

                // pointLogDay
                const docReflogDay = doc(db, 'pointLogDay', formattedDateForDocTIME);
                const subCollectionRefDAY = collection(docReflogDay, 'whisperPoints');
                const newDocRefDay = doc(subCollectionRefDAY, whisperId);

                const newDocSnapDAY = await getDoc(newDocRefDay);
                if (newDocSnapDAY.exists()) {
                    const existingPointsDAY = newDocSnapDAY.data().pointsAdded || 0;
                    await updateDoc(newDocRefDay, {
                        pointsAdded: existingPointsDAY + addedValue,
                        originid
                    });
                } else {
                    await setDoc(newDocRefDay, {
                        pointsAdded: addedValue,
                        originid
                    });
                }
            }

            // addpointPost サブコレクションに投稿リンクを追加
            async function addPostlink(whisperId) {
                try {
                    if (!userId.value) return;
                    const userPostsCollectionRef = collection(db, 'users', userId.value, 'addpointPost');
                    await setDoc(doc(userPostsCollectionRef, whisperId), {});
                } catch (error) {
                    console.error('投稿リンクの追加に失敗しました:', error);
                }
            }

            // メッセージモーダルを開く
            const openMessageModal = (msg, callback = null) => {
                message.value = msg;
                messageCallback.value = callback;
                isMessageModalOpen.value = true;
            };

            // メッセージモーダルを閉じる
            const closeMessageModal = () => {
                isMessageModalOpen.value = false;
                message.value = '';
                if (messageCallback.value) {
                    messageCallback.value();
                    messageCallback.value = null;
                }
            };

            // ========== Return Template Bindings ==========
            return {
                // State
                localPointToAdd,
                isAddingPoint,
                userId,
                modalOpen,
                selectedChargeWhisper,
                positionClass,

                // Methods
                onClickBack,
                onClickAddPoint,
                onClickOpenChargeModal,
                handleCloseChargeModal,

                // メッセージモーダル
                isMessageModalOpen,
                message,
                openMessageModal,
                closeMessageModal,
            };
        }
    };
</script>
  
<style lang="scss" scoped>
    .lxModal {
        display: none;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        z-index: 200;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color:  #00000080;
        font-size: 16px;

        &.open{
            display: flex;
        }

        &.absolute {
            position: absolute;
        }

        &.fixed {
            position: fixed;
        }

        .lxModal-content{
            background: linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
            color: #FFF;
            border-radius: 10px;
            border: 1px solid #00A8A8;
            width: 90%;
            max-width: 400px;
            height: auto;
            padding: 20px;

            /* ✕ボタン */
            .backbtn {
                display: flex;
                justify-content: flex-end;
            
                img{
                    cursor: pointer;
                    width: 30px;
                }

                &.disabled-backbtn {
                    opacity: 0.6;
                    pointer-events: none;
                    cursor: not-allowed;
                }
            }

            .disabled-button {
                opacity: 0.6;
                cursor: not-allowed;
            }

            .disabled-button .smallicon,
            .disabled-backbtn .smallicon {
                filter: grayscale(100%);
            }

            .pointadd{
                gap: 30px;
                display: flex;
                justify-items: center;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                /* タイトル */
                h3{
                    font-size: 20px;
                    line-height: 1.5;
                    text-align: center;
                }

                p {
                    display: flex;
                    align-items: center;

                    .smallicon {
                        width: 26px;
                    }

                    span {
                        padding-left: 10px;
                    }
                }

                /* この投稿のルクス表示欄 */
                &--postlx{
                    p{
                        width: 250px;
                        height: 50px;
                        background: #323232;
                        border-radius: 5px;

                        img{
                        padding: 6px;
                        }
                    }
                }

                /* ルクス付与欄 */
                &--addlx{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 250px;
                    height: 50px;
                    background: #323232;
                    border-radius: 5px;

                    input{
                        width: 54%;
                        background: #D9D9D9;
                        color: #00A8A8;
                        padding: 6px 0 6px 6px;
                        margin: 0 0 0 6px;
                        border-radius: 3px;
                    }
                    
                    button{
                        width: 46%;
                        padding:0 6px;

                        img{
                        width: 100%!important;
                        }
                    }
                }

                /* あなたのルクス表示欄 */
                &--mylx{
                    p{
                        display: flex;
                        justify-content: space-between;
                        width: 250px;
                        height: 50px;
                        background: #323232;
                        border-radius: 5px;

                        button{
                            width: 45%;

                            img{
                                width: 100%!important;
                                padding: 6px;
                            }
                        }
                    }
                }
            }
        }
    }

    .MessageModal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        min-width: 320px;
        height: 100%;
        background-color: #00000080;
        z-index: 300;

        .MessageModal_Body {
            background-color: #323232;
            color: #fff;
            padding: 20px;
            border-radius: 10px;
            border: 1px solid #00a8a8;
            text-align: center;
            width: 280px;
            z-index: 10;
            line-height: 1.5;
            white-space: pre-wrap;

            p{
                font-size: 17px;
                font-weight: 500;
            }

            .btn_Modal {
                margin: 20px 8px 0 8px;
                border: none;
                border-radius: 5px;
                width: 100px;
                height: 35px;
                font-size: 16px;
                background: linear-gradient(180deg, #1903031A 3.32%, #4d4d4d 94.3%), #00A8A8;

                .smallicon {
                    width: 15px;
                    margin-right: 5px;
                }
            }
        }
    }
</style>