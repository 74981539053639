<!-- shareModal.vue -->
<template>
    <div class="shareModal" :class="{ open: isOpen }">
      <div class="shareModal-content">
        <div @click="onClose" class="backbtn">
          <img src="../../assets/backbtn.png" alt="" class="smallicon">
        </div>
  
        <h2>共有</h2>
  
        <div class="buttonbox">
          <button @click="shareToTwitter">
            <img src="../../assets/link3.png" alt="Twitterで共有" class="share-icon">
          </button>
          <button @click="shareToInstagram">
            <img src="../../assets/link2.png" alt="Instagramで共有" class="share-icon">
          </button>
          <button @click="shareToFacebook">
            <img src="../../assets/link6.png" alt="Facebookで共有" class="share-icon">
          </button>
          <button @click="shareToLine">
            <img src="../../assets/link5.png" alt="LINEで共有" class="share-icon">
          </button>
          <button @click="shareToDiscord">
            <img src="../../assets/link4.png" alt="Discordで共有" class="share-icon">
          </button>
        </div>
  
        <div class="shareUrlBox">
          <input
            type="text"
            :value="shareUrl"
            readonly
            class="shareUrlInput"
            @click="selectShareUrl"
          />
          <button class="btn-shareUrl" @click="copyShareUrl">
            <i class="fa fa-clone" aria-hidden="true"></i>コピー
          </button>
          <div v-if="copySuccess" class="copy-success-popup">
            クリップボードにコピーしました
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
  export default {
    name: 'ShareModal',
    props: {
      // 親から「モーダルを開く／閉じる」フラグを受け取る想定
      isOpen: {
        type: Boolean,
        default: false
      },
      // 共有URL (postdetail のリンク等)
      shareUrl: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        copySuccess: false
      };
    },
    methods: {
      // モーダルを閉じるイベント
      onClose() {
        // カスタムイベントをEmitして親に閉じる操作を通知
        this.$emit('close');
      },
      // 共有URLをクリックしたときに選択
      selectShareUrl(event) {
        event.target.select();
      },
      // 共有URLをクリップボードにコピー
      async copyShareUrl() {
        try {
          await navigator.clipboard.writeText(this.shareUrl);
          this.copySuccess = true;
          setTimeout(() => {
            this.copySuccess = false;
          }, 3000);
        } catch (error) {
          alert('リンクのコピーに失敗しました。再度お試しください。');
        }
      },
      // 共有メソッド各種
      shareToTwitter() {
        const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.shareUrl)}&text=${encodeURIComponent('#DiPosty')}`;
        window.open(twitterUrl, '_blank');
      },
      shareToFacebook() {
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.shareUrl)}`;
        window.open(facebookUrl, '_blank');
      },
      async shareToInstagram() {
        // Instagram は直接URLを渡してシェアというやり方がなく、
        // 「URLをコピーしてInstagramの投稿にペースト」を促す等が一般的
        try {
          await navigator.clipboard.writeText(this.shareUrl);
          alert('リンクをコピーしました。Instagramの投稿に貼り付けてください。');
          window.open('https://www.instagram.com', '_blank');
        } catch (error) {
          alert('処理に失敗しました。再度お試しください。');
        }
      },
      shareToLine() {
        const lineUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(this.shareUrl)}`;
        window.open(lineUrl, '_blank');
      },
      async shareToDiscord() {
        try {
          await navigator.clipboard.writeText(this.shareUrl);
          alert('リンクをコピーしました。Discordアプリを開いて、リンクを貼り付けてください。');
          window.open('https://discord.com/channels/@me', '_blank');
        } catch (error) {
          alert('処理に失敗しました。再度お試しください。');
        }
      }
    }
  };
</script>
  
<style lang="scss" scoped>

    .shareModal {
        position: absolute;
        display: none;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        z-index: 200;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #3232328c;

        &.open{
            display: flex;
        }

        .shareModal-content {
            width: 95%;
            max-width: 550px;
            height: auto;
            padding: 20px 20px 40px 20px;
            background: linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
            color: #FFF;
            border-radius: 10px;
            border: 1px solid #00A8A8;

            /* ✕ボタン */
            .backbtn {
                display: flex;
                justify-content: flex-end;
                
                img {
                    cursor: pointer;
                    width: 30px;
                }
            }

            h2 {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 15px;
            }

            .buttonbox {
                display: flex;
                gap: 16px;
                margin-bottom: 25px;
            }

            .shareUrlBox {
                display: flex;
                justify-content: space-between;
                height: 50px;
                background: #323232;
                border-radius: 5px;
                align-items: center;
                position: relative;

                .shareUrlInput {
                    margin-left: 10px;
                    height: 30px;
                    font-size: 16px;
                    width: calc(100% - 110px); 
                }

                .btn-shareUrl {
                    background: #00A8A8;
                    color: #FFFFFF;
                    width: 85px;
                    height: 30px;
                    font-size: 15px;
                    font-weight: 400;
                    padding: 5px 10px;
                    border-radius: 15px;
                    letter-spacing: 0px;
                    margin-right: 10px;
                }

                .copy-success-popup {
                    position: absolute; 
                    top: 110%;
                    right: 0;
                    background-color: #fff;
                    color: #222222;
                    font-size: 15px;
                    padding: 5px 10px;
                    border-radius: 10px;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                    animation: fadeInOut 3s forwards;
                }

                /* フェードイン・フェードアウトのアニメーション */
                @keyframes fadeInOut {
                    0% { opacity: 0; transform: translateY(10px); }
                    10% { opacity: 1; transform: translateY(0); }
                    90% { opacity: 1; transform: translateY(0); }
                    100% { opacity: 0; transform: translateY(10px); }
                }
            }
        }
    }
</style>