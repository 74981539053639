<!-- PostModal.vue -->

<template>
    <div class="postmodal" :class="{ open: isPostModalOpen }">
        <div class="modalbox">
            <button @click="closePostModal" class="backbtn"><img src="../../assets/backbtn.png" alt="" class="smallicon"></button>
            <div class="modalbox--header">
                <div>
                    <label for="customDate">
                        <input type="date" id="customDate" v-model="todays" @input="updateToday">
                    </label>
                </div>

                <button @click="Savedraft">
                    下書き保存
                </button>
            </div>

            <div class="modalbox--index">
                <div>
                    タイトル
                    <span>{{ title.length }}/64</span>
                    <b>必須</b>
                </div>
                <input type="text" v-model="title" @input="updateCount('title')" class="modalbox--text" placeholder="タイトルを書く。">
            </div>


            <div class="tags">
                <div class="modalbox--index" v-for="(tag, index) in tags" :key="index">
                    <div>
                        タグ
                        <span>{{ tag.length }}/16</span>
                    </div>
                    <button @click="removeTag(index)" class="remtag">
                        <img src="../../assets/backbtn.png" alt="" class="smallicon">
                    </button>
                    <input type="text" v-model="tags[index]" @input="updateCount('tags', index)" class="modalbox--text" placeholder="タグを書く。">
                </div>
                <button @click="addTagInput" class="addtag" :class="{ 'hidden': tags.length >= 16 }">
                    タグを追加
                </button>
            </div>

            <div class="modalbox--index">
                <div>
                    前書き
                    <span>{{ lead.length }}/128</span>
                </div>
                <!-- <input type="text" v-model="lead" @input="updateCount('lead')" class="modalbox--lead"> -->
                <textarea v-model="lead" @input="updateCount('lead')" name="" id="" cols="30" rows="5" class="modalbox--lead" placeholder="前書きを書く。"></textarea>
            </div>
            <div class="modalbox--img modalbox--index">
                <div>
                    画像選択
                    <span>{{ selectedImages.length }}/4</span>
                </div>
                <div class="images">
                    <label class="image" :class="{ 'hidden': selectedImages.length >= 4 }">
                        <input type="file" accept="image/*" @change="handleImageSelection" hidden />
                    </label>
                    <div class="modalbox--imglist modalbox--index" v-if="selectedImages.length > 0">
                        <ul>
                            <li v-for="(image, index) in selectedImages" :key="index">
                                <img :src="getObjectURL(image)" alt="Selected Image" @click="showDelImgModal(index)">
                                <button @click="removeImage(index)"></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modalbox--index">
                <div>
                    本文
                    <span>{{ body.length }}/4096</span>
                </div>
                <textarea v-model="body" @input="updateCount('body')" name="" id="" cols="30" rows="8" class="modalbox--textarea" placeholder="本文を書く。"></textarea>
            </div>

            <div class="modalbox--index modalbox--postbtn">
                <button @click="newpost" class="postbtn" :class="{ disabled: isPosting }" :disabled="isPosting">
                    <div class="postText"><span v-if="isPosting" class="loading-overlay"><i class="fa fa-spinner fa-pulse fa-3x fa-fw loading-image"></i></span>投 稿</div>
                    <div class="postContents">タグ解放 {{ tagsCount }}</div>
                    <div class="postContents"><img src="../../assets/lxinpost.png" alt="" class="smallicon">-{{ needLx }}</div>
                </button>
            </div>
        </div>
    </div>

    <div class="MessageModal" v-if="isBackMessageModalOpen" @click.self="closeBackMessageModal">
        <div class="back_MessageModal_Body">
            <p>記事を下書き保存せず削除しますか？</p>
            <button class="btn_Modal" @click="DeletePost">削除</button>
            <button class="btn_Modal" @click="SavePost">保存</button>
        </div>
    </div>

    <div class="MessageModal" v-if="isNotLxMsgModalOpen" @click.self="closeNotLxMsgModal">
        <div class="NotLxMsgModal_Body">
            <div class="closeNotLxMsgbtn"> 
                <button @click="closeNotLxMsgModal">
                    <img src="../../assets/backbtn.png" alt="" class="smallicon">
                </button>
            </div>
            <p>投稿に必要なルクスが不足しています。</p>
            <button class="btn_Modal" @click="showChargeLx"><img src="../../assets/lxinpost.png" alt="" class="smallicon">チャージ</button>
            <button class="btn_Modal" @click="SavePost">下書き保存</button>
        </div>
    </div>

    <!-- ChargeModal コンポーネントの追加 -->
    <ChargeModal :modalOpen="isChargeModalOpen" @closeChargeModal="closeChargeModal"></ChargeModal>

    <div class="MessageModal" v-if="isMessageModalOpen" @click.self="closeMessageModal(closeMode)">
        <div class="MessageModal_Body">
            <p>{{ message }}</p>
            <button class="btn_Modal" @click="closeMessageModal(closeMode)">OK</button>
        </div>
    </div>

    <div class="MessageModal" v-if="isDelImgModalOpen" @click.self="closeDelImgModal">
        <div class="back_MessageModal_Body">
            <p>画像を消去しますか？</p>
            <button class="btn_Modal" @click="PostDelImg(DelImg)">はい</button>
            <button class="btn_Modal" @click="closeDelImgModal">いいえ</button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch, nextTick } from 'vue';
import { collection, doc, setDoc, getDoc, runTransaction, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../../main';
import { ref as storageRef, getStorage, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useRoute } from 'vue-router';
import ChargeModal from '../chargeModal/chargeModal.vue';

export default {
    components: {
        ChargeModal
    },
    props: {
        isPostModalOpen: Boolean,
        selectDay: String,
    },
    setup(props, { emit }) {
        // 現在のルート情報
        const route = useRoute();
        
        // ユーザー情報
        const currentUser = ref(null);
        const postmodal = ref(null);

        // 記事用
        const title = ref('');
        const tags = ref(['']);
        const lead = ref('');
        const body = ref('');
        const setdays = ref('');
        const todays = ref('');
        const selectedImages = ref([]);

        const setday = ref('');

        const tagsCount = ref(1);
        const needLx = ref(300);

        // 投稿中フラグ
        const isPosting = ref(false);

        // 各種モーダル用
        const isBackMessageModalOpen = ref(false);
        const isNotLxMsgModalOpen = ref(false);
        const isMessageModalOpen = ref(false);
        const isChargeModalOpen = ref(false);
        const isDelImgModalOpen = ref(false);
        const message = ref('');
        const closeMode = ref('');
        const DelImg = ref(null);
        const isMobileDevice = ref(false);

        const getFormattedDate = () => {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        onMounted(() => {
            todays.value = props.selectDay || getFormattedDate();
            isMobileDevice.value = window.matchMedia('(pointer: coarse)').matches; // タッチデバイスの判定
            initTodayDate();
            auth.onAuthStateChanged(user => {
                currentUser.value = user;
            });
        });

        watch(
            () => props.selectDay,
            (newDay) => {
                todays.value = newDay;
            }
        );

        watch(selectedImages, async (newVal) => {
            await nextTick();
            const inputElement = postmodal.value ? postmodal.value.querySelector('input[type="file"]') : null;
            if (inputElement) {
                if (newVal.length >= 4) {
                inputElement.classList.add('hidden');
                } else {
                inputElement.classList.remove('hidden');
                }
            }
        }, { deep: true });

        const updateToday = () => {
            const selectedDate = new Date(todays.value);
            selectedDate.setHours(0, 0, 0, 0);
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate.getDate()).padStart(2, '0');
            setday.value = `${year}-${month}-${day}`;
            sessionStorage.setItem('selectDAY', todays.value);
        };

        const showBackMessageModal = () => isBackMessageModalOpen.value = true; // 削除・保存確認メッセージDLGの表示
        const closeBackMessageModal = () => isBackMessageModalOpen.value = false; // 削除・保存確認メッセージDLGの非表示
        const showNotLxMsgModal = () => isNotLxMsgModalOpen.value = true; // ルクス不足メッセージDLGの表示
        const closeNotLxMsgModal = () => isNotLxMsgModalOpen.value = false; // ルクス不足メッセージDLGの非表示

        const showChargeLx = () => {
            closeNotLxMsgModal();
            isChargeModalOpen.value = true;
        };
        const closeChargeModal = () => isChargeModalOpen.value = false;

        // 確認メッセージDLGの表示
        const showMessageModal = (msg, mode) => {
            message.value = msg;
            closeMode.value = mode;
            isMessageModalOpen.value = true;
        };

        // 確認メッセージDLGの非表示
        const closeMessageModal = (closeMode) => {
            isMessageModalOpen.value = false;
            if (closeMode == 'closePostModal') {
                emit('update:isPostModalOpen', false);
            }
            if (closeMode == 'closeAddPostModal') {
                emit('update:isPostModalOpen', false);
                window.location.reload();
            }
        };

        // 画像削除確認メッセージDLGの表示
        const showDelImgModal = (index) => {
            // タッチデバイスならモーダルを出す
            if (isMobileDevice.value) {
                DelImg.value = index;
                isDelImgModalOpen.value = true;
            }
        };

        // 確認メッセージからの画像削除処理
        const PostDelImg = (DelImg) => {
            removeImage(DelImg);
            isDelImgModalOpen.value = false;
        };

        // 画像削除確認メッセージDLGの非表示
        const closeDelImgModal = () => {
            isDelImgModalOpen.value = false;
        }

        // 「✕」ボタン押下時
        const closePostModal = () => {
            if (title.value.trim() !== '' || tags.value.some(tag => tag.trim() !== '') || 
                lead.value.trim() !== '' || selectedImages.value.length > 0 || 
                body.value.trim() !== '') {
                // 本文、タグ、前書き、本文のいずれかが入力されている、または画像が選択されている場合、削除・保存確認メッセージを表示
                showBackMessageModal();
            } else {
                // 記事投稿・編集Mを非表示
                emit('update:isPostModalOpen', false);
            }
        };

        //記事の削除処理
        const DeletePost = () => {
            closeBackMessageModal();
            closeModal();
            emit('update:isPostModalOpen', false);
        };

        //記事の保存処理
        const SavePost = () => {
            closeBackMessageModal();
            closeNotLxMsgModal();
            Savedraft();
        };
        const closeModal = () => {
            title.value = ''; // タイトルをクリア
            lead.value = ''; // 前書きをクリア
            body.value = ''; // 本文をクリア
            tags.value = ['']; // タグをクリア
            selectedImages.value = []; // 選択された画像をクリア
        };

        const updateCount = (field, index) => {
            // フィールドごとに文字数を更新
            if (field === 'title' && title.value.length > 64) {
                //タイトル、最大入力文字数は64
                title.value = title.value.slice(0, 64);
            }
            if (field === 'tags' && tags.value[index].length > 16) {
                //タグ、最大入力文字数は16
                tags.value[index] = tags.value[index].slice(0, 16);
            }
            if (field === 'lead' && lead.value.length > 128) {
                //前書き、最大入力文字数は128
                lead.value = lead.value.slice(0, 128);
            }
            if (field === 'body' && body.value.length > 4096) {
                //本文、最大入力文字数は128
                body.value = body.value.slice(0, 4096);
            }
        };

        const handleImageSelection = (event) => {
            const files = event.target.files;
            for (let i = 0; i < files.length; i++) {
                if (selectedImages.value.length < 4) {
                    selectedImages.value.push(files[i]);
                }
            }
            // input の value をリセット
            event.target.value = null;
        };

        const removeImage = (index) => {
            selectedImages.value.splice(index, 1);
        };

        const getObjectURL = (file) => {
            if (!file) return '';
            return window.URL.createObjectURL(file);
        };

        const initTodayDate = () => {
            const storedDate = sessionStorage.getItem('selectDAY');
            if (storedDate) {
                todays.value = storedDate;
            } else {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, '0');
                const day = String(today.getDate()).padStart(2, '0');
                todays.value = `${year}-${month}-${day}`;
            }
            setday.value = todays.value;
        };

        // 記事投稿処理
        const newpost = async () => {
            if (isPosting.value) {
                // 既に投稿処理中の場合は何もしない
                return;
            }
            isPosting.value = true; // 投稿処理を開始

            try {
                if (!todays.value) {
                    showMessageModal('日付を入力してください。', 'closeMessageModal');
                    return;
                }
                if (title.value.trim() === '') {
                    showMessageModal('タイトルを入力してください。', 'closeMessageModal');
                    return;
                }

                const uid = currentUser.value.uid;
                const userRef = doc(db, 'users', uid);

                // 画像のアップロード
                const storage = getStorage();
                const imageUrls = [];
                for (let i = 0; i < selectedImages.value.length; i++) {
                    const image = selectedImages.value[i];
                    const getStorageRef = storageRef(storage, `images/${currentUser.value.uid}/${Date.now()}_${image.name}`);
                    await uploadBytes(getStorageRef, image);

                    // 画像のダウンロードURLを取得して配列に追加
                    const downloadURL = await getDownloadURL(getStorageRef);
                    imageUrls.push(downloadURL);
                }

                // 投稿データの準備
                const postData = {
                    title: title.value,
                    tags: tags.value,
                    tagsCount: tags.value.length,
                    lead: lead.value,
                    body: body.value,
                    todays: todays.value,
                    setdays: setday.value,
                    imageUrls: imageUrls,
                    point: 0,
                    originid: '',
                    dataCreated: serverTimestamp(),
                    dataUpdated: serverTimestamp(),
                };

                await runTransaction(db, async (transaction) => {
                    // ユーザードキュメントの取得
                    const userDoc = await transaction.get(userRef);
                    if (!userDoc.exists()) {
                        throw new Error('USER NOT FOUND');
                    }

                    const userData = userDoc.data();
                    const currentPoints = userData.point || 0;

                    if (currentPoints < needLx.value) {
                        throw new Error('NOT ENOUGH LX');
                    }

                    postData.originid = userData.originid;

                    // 投稿ドキュメントのリファレンス作成
                    const postRef = doc(collection(db, 'whispers'));

                    // 長さログのドキュメントのリファレンスとデータ取得
                    const totalLength = title.value.length + lead.value.length + body.value.length;
                    const todayDate = new Date();
                    const formattedDate = todayDate.toISOString().split('T')[0].replace(/-/g, '');

                    const lengthLogRef = doc(db, 'users', uid, 'myPostlengthLog', formattedDate);
                    const lengthLogDoc = await transaction.get(lengthLogRef);

                    let newALLlength = totalLength;
                    if (lengthLogDoc.exists()) {
                        const existingData = lengthLogDoc.data();
                        newALLlength += existingData.ALLlength || 0;
                    }

                    // ユーザーのポイントを更新
                    transaction.update(userRef, {
                        point: currentPoints - needLx.value,
                    });

                    // 投稿データを保存
                    transaction.set(postRef, postData);

                    // 長さログを更新または作成
                    transaction.set(lengthLogRef, {
                        ALLlength: newALLlength,
                    });
                });

                // 投稿後の処理
                closeModal();
                showMessageModal('記事が投稿されました。', 'closeAddPostModal');

            } catch (error) {
                if (error.message === 'NOT ENOUGH LX') {
                    // ルクスが不足し投稿できない場合
                    showNotLxMsgModal();
                } else {
                    // エラーメッセージを表示
                    showMessageModal('投稿に失敗しました。\nサーバーに問題が発生している可能性があります。\nしばらくしてから再試行してください。', 'closeMessageModal');
                }
            } finally {
                // 処理終了後にボタンを再度有効化
                isPosting.value = false;
            }
        };

        //下書き保存処理
        const Savedraft = async () => {
            try {
                // 本文、タグ、前書き、本文が入力されていない、かつ画像が選択されていない場合
                if (title.value.trim() === '' && tags.value.every(tag => tag.trim() === '') &&
                    lead.value.trim() === '' && selectedImages.value.length === 0 &&
                    body.value.trim() === ''){
                    showMessageModal('保存する記事がありません。', 'closeMessageModal');
                    return;
                }

                if (!todays.value) {
                    showMessageModal('日付を入力してください。', 'closeMessageModal');
                    return;
                }

                // Storageオブジェクトを取得
                const storage = getStorage();

                // ユーザーのドキュメントを取得
                const userRef = doc(db, 'users', currentUser.value.uid);
                const userDoc = await getDoc(userRef);

                // 画像のアップロード
                const imageUrls = [];
                for (let i = 0; i < selectedImages.value.length; i++) {
                    const image = selectedImages.value[i];
                    const getStorageRef = storageRef(storage, `images/${currentUser.value.uid}/${Date.now()}_${image.name}`);
                    await uploadBytes(getStorageRef, image);

                    // 画像のダウンロードURLを取得して配列に追加
                    const downloadURL = await getDownloadURL(getStorageRef);
                    imageUrls.push(downloadURL);
                }

                // Firestoreに保存するデータを作成
                const postData = {
                    title: title.value,
                    tags: tags.value,
                    lead: lead.value,
                    body: body.value,
                    todays: todays.value,
                    setdays: setday.value,
                    imageUrls: imageUrls,
                    point:0,
                    originid: userDoc.data().originid, // ユーザーのidを取得
                    dataCreated: serverTimestamp(),
                    dataUpdated: serverTimestamp(),
                };

                // Firestoreにデータを保存
                const postRef = doc(collection(db, 'users', currentUser.value.uid, 'savedraft'));
                await setDoc(postRef, postData);

                // 下書き保存後の処理
                closeModal();

                const currentPath = route.path;
                let closeMode = 'closePostModal'
                if (currentPath === '/draftslist') {
                    // ルートが/draftslistの場合、記事投稿モーダル非表示後リロードする
                    closeMode = 'closeAddPostModal'
                }
                showMessageModal('記事を下書き保存しました。', closeMode);

            } catch (error) {
                // console.log(error)
                // エラー発生時、エラーメッセージを表示
                showMessageModal('下書き保存に失敗しました。\nサーバーに問題が発生している可能性があります。しばらくしてから再試行してください。', 'closeMessageModal');
            }
        };

        const addTagInput = () => {
            tags.value.push(''); // タグ入力欄を追加する
            updateNeedLx(); // 消費ルクスの計算
        };

        const removeTag = (index) => {
            tags.value.splice(index, 1); // 指定されたインデックスのタグを削除する
            updateNeedLx(); // 消費ルクスの計算
        };

        const updateNeedLx = () => {
            let tagsLx = 0;
            if (tags.value.length > 0) {
                let tagsCount = tags.value.length - 1;
                tagsLx = tagsCount * 20;
            }

            tagsCount.value = tags.value.length;
            needLx.value = tagsLx + 300;
        };

        return {
            // ユーザー情報
            currentUser,

            // 投稿記事
            title,
            lead,
            body,
            todays,
            setdays,
            tags,
            selectedImages,
            setday,
            tagsCount,
            needLx,

            // 投稿中フラグ
            isPosting,
            
            // 各種モーダルフラグ
            isBackMessageModalOpen,
            isMessageModalOpen,
            isDelImgModalOpen,
            isNotLxMsgModalOpen,
            isChargeModalOpen,
            message,
            closeMode,
            DelImg,
            
            // 日付関連関数
            getFormattedDate,
            updateToday,
            initTodayDate,

            // 各種モーダル制御関数
            showBackMessageModal,
            closeBackMessageModal,
            showNotLxMsgModal,
            closeNotLxMsgModal,
            showChargeLx,
            closeChargeModal,
            showMessageModal,
            closeMessageModal,
            showDelImgModal,
            PostDelImg,
            closeDelImgModal,

            // 投稿モーダル
            closePostModal,
            DeletePost,
            SavePost,
            closeModal,

            // 制限系
            updateCount,
            addTagInput,
            removeTag,

            // 画像系
            handleImageSelection,
            removeImage,
            getObjectURL,
            
            // 投稿・下書き
            newpost,
            Savedraft,  
        };
    },
};
</script>

<style lang="scss" scoped>
    .postmodal {
        display: none;
    }

    .tags{
        .modalbox--index{
            white-space: nowrap;
            .modalbox--text{
                width: 400px !important;
                margin-left: 10px;
                @media (max-width: 599px) {
                    width: calc(100% - 36px) !important;
                }
            }
        }
        .addtag{
            margin: 0 20px;
            font-size: 15px;
            background: #C1C1C1;
            color: #323232;
            padding: 5px 15px;
            border-radius: 50px;
            font-weight: 600;
        }
        .remtag{
            position: relative;
        }
        button{
            .smallicon{
                width: 26px;
            }
        }
    }

    .postmodal.open {
        display: block;
        position: absolute;
        z-index: 10000;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        background:linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 320px;
        .modalbox{
            width: 80%;
            height: 95%;
            overflow-x: scroll;
            overflow-x: auto;
            color: #fff;
            background:linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
            border-radius: 10px ;
            @media screen and (max-width: 1000px) {
                width: 100%;
                height: 95%;
            }
            &--header{
                display: flex;
                align-items: center;
                font-size: 50px;
                font-weight: 700;
                justify-content: space-between;
                margin: 20px;
                @media screen and (max-width: 599px) {
                    width: 90%;
                    min-width: 290px;
                    font-size: 38px;
                    align-items: flex-end;
                    flex-direction: column-reverse;
                }
                @media screen and (max-width: 360px) {
                    font-size: 34px;
                }
                input[type="date"] {
                    width: calc(100% - 1px);
                    box-sizing: border-box;
                    background-image: url("../../assets/calendar.png"); // カスタムアイコンのパス
                    background-repeat: no-repeat;
                    background-position: right 10px center; // アイコンの位置を右側に設定
                    cursor: pointer; // カーソルをポインターに設定
                    -webkit-appearance: none; // ブラウザデフォルトのスタイルを無効化
                    appearance: none;
                    background-size: 30px; // アイコンのサイズを指定
                    padding-right: 40px; // カレンダーアイコンのためのスペースを追加
                    text-align: left;
                }
                input[type="date"]::-webkit-calendar-picker-indicator {
                    opacity: 0; // インジケーターを透明にする
                    width: 40px; // インジケーターの幅をアイコンの幅に合わせる
                    height: 30px; // インジケーターの高さをアイコンの高さに合わせる
                    cursor: pointer; // カーソルをポインターに設定
                    position: relative;
                    right: -30px; // 右からの位置を指定
                }
                div { 
                    @media (max-width: 599px) {
                        width: 100%;
                        min-width: 290px;
                    }
                }
                button{
                    background: #C1C1C1;
                    color: #323232;
                    font-size:15px;
                    padding:5px 15px;
                    border-radius: 50px;
                    font-weight: 600;
                }
            }
            &--imglist{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                margin: 5px 20px !important;
                @media screen and (max-width: 400px) {
                    margin: 0 5px !important;
                }
                ul{
                    margin-top: 5px;
                    width: 100%;
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-template-rows: auto auto;
                    grid-gap: 10px;
                    @media screen and (max-width: 599px) {
                        margin-top: 0;
                        grid-template-columns: 1fr;
                        grid-template-rows: auto;
                    }
                    li{
                        border-radius: 5px;
                        border: 3px solid #10AE33;
                        width: 350px;
                        height: calc(9 / 16 * 350px);
                        position: relative;
                        margin: 0 15px;
                        overflow: hidden;
                        @media screen and (max-width: 1200px) {
                            width: 270px;
                            height: calc(9 / 16 * 270px);
                        }
                        @media screen and (max-width: 1000px) {
                            width: 300px;
                            height: calc(9 / 16 * 300px);
                        }
                        @media screen and (max-width: 860px) {
                            width: 250px;
                            height: calc(9 / 16 * 250px);
                        }
                        @media screen and (max-width: 730px) {
                            width: 200px;
                            height: calc(9 / 16 * 200px);
                            margin: 0 8px;
                        }
                        @media screen and (max-width: 599px) {
                            width: 300px;
                            height: calc(9 / 16 * 300px);
                            margin: 8px;
                        }
                        @media screen and (max-width: 470px) {
                            width: 260px;
                            height: calc(9 / 16 * 260px);
                        }
                        @media screen and (max-width: 420px) {
                            width: 200px;
                            height: calc(9 / 16 * 200px);
                        }
                        img{
                            width: 100%;
                            height: 100%;
                            //object-fit: contain;
                            object-fit: cover;
                        }
                        button{
                            display: none;
                            position: absolute;
                            background: url("../../assets/X.png") no-repeat center center; /* 画像のパスと表示設定 */
                            background-size: contain;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: #C1C1C1;
                            right: 10px;
                            top: 10px;
                            align-items: center;
                            justify-content: center;
                            color: #323232;
                        }
                    }
                    @media (hover: hover) and (pointer: fine) {
                        li:hover button {
                            display: flex;
                        }
                    }
                }
            }
            .backbtn{
                position: relative;
                left: 95%;
                top: 15px;
                width: 30px;
                @media screen and (max-width: 599px) {
                    left: 86%;
                    top: 2px;
                }
                img{
                    cursor: pointer;
                    width: 30px;
                }
            }
            &--index{
                margin: 20px;
                font-size: 15px;
                b{
                    font-size: 12px;
                    color: #ea0f29;
                    margin-left:10px;
                    font-weight: 400;
                }
            }
            &--text{
                width: 100%;
                background-color: #323232;
                padding: 5px;
            }

            &--lead{
                width: 100%;
                background-color: #323232;
                padding: 5px;
            }
            &--textarea{
                width: 100%;
                background-color: #323232;
                padding: 5px;
            }
            &--postbtn{
                text-align: center;
            }
            .postbtn{
                width: 150px;
                padding: 10px 0;
                text-align: center;
                border-radius: 10px;
                background: linear-gradient(180deg, #1903031A 3.32%, #323232 94.3%), #00A8A8;
                .postText{
                    font-size: 30px;
                    font-weight: bold;
                    margin-bottom: 7px;
                }
                .postContents{
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 25px;
                    font-size: 18px;
                    img{
                        width: 15px;
                        margin-right: 10px;
                    }
                }
                .loading-overlay {
                    color: #00A8A8;
                    font-size: 10px;
                }
            }
            .postbtn.disabled {
                height: 111px;
                color: #aaa9a9;
                background: linear-gradient(180deg, #1903031A 3.32%, #323232 94.3%), #9b9898;
                cursor: not-allowed;
            }
            // スクロールバーを非表示にする
            &::-webkit-scrollbar {
                display: none;
            }
            // スクロール可能な状態でスクロールバーを表示する
            &:hover,
            &:active,
            &:focus {
                &::-webkit-scrollbar {
                display: block;
                width: 0px; // スクロールバーの幅
                }
                &::-webkit-scrollbar-track {
                background-color: transparent;
                }
                &::-webkit-scrollbar-thumb {
                background-color: #828f85; // スクロールバーの色
                border-radius: 5px;
                }
            }
        }
    }

    .modalbox--img{
        width: 96%;
        .images{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
        }
        label.image{
            cursor: pointer;
            width: 150px;
            height: 180px;
            min-width: 150px;
            min-height: 180px;
            background: url("../../assets/imgae.png") no-repeat center center; /* 画像のパスと表示設定 */
            background-size: contain;
            font-size: 0;
            @media screen and (max-width: 860px) {
                width: 120px;
                height: 150px;
                min-width: 120px;
                min-height: 150px;
            }
            @media screen and (max-width: 599px) {
                width: 100px;
                height: 130px;
                min-width: 100px;
                min-height: 130px;
            }
            @media screen and (max-width: 420px) {
                width: 60px;
                height: 90px;
                min-width: 60px;
                min-height: 90px;
            }
        }
        label.image::file-selector-button {
            display: none;
        }
        label.image::file-selector-button::before {
            content: none;
        }
    }
    .hidden {
        cursor: not-allowed;
        pointer-events: none;
        display: none;
    }

    .tag{
        a{
            font-size: 13px;
            letter-spacing: 0px;
            margin-left: 10px;
        }
    }

    // 記事の削除/保存確認DLG、確認メッセージDLG　オーバーレイ
    .MessageModal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000080;
        z-index: 11000;
    }
    // 記事の削除/保存確認DLG
    .back_MessageModal_Body {
        background-color: #323232;
        color: #fff;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #00a8a8;
        text-align: center;
        z-index: 40;

        p{
            font-size: 18px;
            font-weight: 500;
        }
    }
    .NotLxMsgModal_Body {
        background-color: #323232;
        color: #fff;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #00a8a8;
        text-align: center;
        z-index: 40;

        .closeNotLxMsgbtn {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 15px;
            img{
                cursor: pointer;
                width: 20px;
            }
        }
        p{
            font-size: 18px;
            font-weight: 500;
        }
        .btn_Modal {
            img{
                width: 15px;
                margin-right: 5px;
            }
        }
    }
    // 確認メッセージDLG
    .MessageModal_Body {
        background-color: #323232;
        color: #fff;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #00a8a8;
        text-align: center;
        width: 300px;
        z-index: 40;

        p{
            font-size: 18px;
            font-weight: 500;
            white-space: pre-wrap;
        }
    }
    // 記事の削除/保存確認DLG、確認メッセージDLG ボタン
    .btn_Modal {
        margin: 20px 8px 0 8px;
        border: none;
        border-radius: 5px;
        width: 100px;
        height: 35px;
        font-size: 16px;
        background: linear-gradient(180deg, #1903031A 3.32%, #4d4d4d 94.3%), #00A8A8;
    }
</style>