<!-- chargeModal.vue -->

<template>
    <div class="chargemodal" :class="[positionClass, { 'open': modalOpen }]">
        <div class="modal-content">
            <div class="closeBtn"> 
                <button @click="closeModal">
                    <img src="../../assets/backbtn.png" alt="" class="smallicon">
                </button>
            </div>
            <div class="title">ルクスの購入</div>
            <div id="product">
                <div class="product" v-for="product in products" :key="product.id">
                    <div class="price-item" v-for="price in sortedPrices(product.prices)" :key="price.id">
                        <!-- フォームに @click イベントを追加 -->
                        <form @click.prevent="createSub(price.id)" class="product-form">
                            <div class="price">
                                <span>{{ price.unit_amount * 7}}ルクス</span>
                                <span>￥{{ price.unit_amount }}</span>
                            </div>
                            <div class="submitBtn">
                                <!-- ボタンのタイプを "button" に変更 -->
                                <button type="button" class="cvbtn">購入する</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'; 
import { db } from '../../main';
import { auth } from '../../main';
import { collection, getDocs, query, where, doc, addDoc, onSnapshot, getFirestore } from 'firebase/firestore';

export default {
    props: {
        modalOpen: Boolean,
        // 親から受け取る: モーダルの表示位置クラス
        positionClass: {
            type: String,
            default: 'absolute'  // 特に指定がなければ absolute 扱い
        }
    },
    emits: ['closeChargeModal'], // 発行するイベントを宣言
    setup(props, { emit }) {
        const products = ref([]);
        const currentUser = ref(null);

        const positionClass = computed(() => props.positionClass);

        const closeModal = () => {
            emit('closeChargeModal');
        };

        const fetchProducts = async () => {
            const productsCollection = collection(db, 'products');
            const q = query(productsCollection, where('active', '==', true));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                const productData = { id: doc.id, ...doc.data(), prices: [] };
                products.value.push(productData);
            });
        };

        const fetchPrices = async () => {
            for (const product of products.value) {
                const productRef = doc(db, 'products', product.id);
                const pricesQuery = query(collection(productRef, 'prices'), where('active', '==', true));
                const pricesQuerySnapshot = await getDocs(pricesQuery);
                pricesQuerySnapshot.forEach((doc) => {
                    product.prices.push({ id: doc.id, unit_amount: doc.data().unit_amount });
                });
            }
        };

        const sortedPrices = (prices) => {
            return prices.slice().sort((a, b) => {
                if (a.unit_amount === null) return 1;
                if (b.unit_amount === null) return -1;
                return a.unit_amount - b.unit_amount;
            });
        };

        onMounted(async () => {
            await fetchProducts();
            await fetchPrices();

            auth.onAuthStateChanged(async user => {
                if (user) {
                    currentUser.value = user.uid;
                } else {
                    currentUser.value = null;
                }
            });
        });

        // createSub 関数の引数を priceId に変更
        const createSub = async (priceId) => {

            const db = getFirestore();
            const collectionRef = collection(
                db,
                "customers",
                auth.currentUser.uid,
                "checkout_sessions"
            );

            try {
                const docRef = await addDoc(collectionRef, {
                    price: priceId,
                    mode: 'payment',
                    success_url: window.location.origin + '/',
                    cancel_url: window.location.origin + '/',
                });

                onSnapshot(docRef, async (snap) => {
                    const { error, url } = snap.data();

                    if (error) {
                        //console.log(`エラーが発生しました: ${error.message}`);
                    }

                    if (url) {
                        window.location.assign(url);
                    }
                });
            } catch (error) {
                //console.log(`支払い作成中にエラーが発生しました: ${error.message}`);
            }
        }

        return {
            closeModal,
            products,
            createSub,
            sortedPrices,
            positionClass
        };
    }
};
</script>





<style lang="scss" scoped>

    .chargemodal{
        z-index: 11000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color:  #00000080;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        display: none;
        &.open{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        &.absolute {
            position: absolute;
        }

        &.fixed {
            position: fixed;
        }

        .modal-content{
            background:linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
            color: #fff;
            width: 400px;
            height: 330px;
            padding: 20px;
            border-radius: 15px;
            border: 1px solid #00a8a8;
            text-align: center;
            z-index: 40;
            @media (max-width: 440px) {
                width: 90%;
                min-width: 300px;
            }

            .closeBtn {
            display: flex;
            justify-content: flex-end;
            img{
                cursor: pointer;
                width: 30px;
            }
        }
        }
        .product-form{
            color: #222222;
            height: 50px;
            width: 100%;
            background: #FFFFFF;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 15px;
            cursor: pointer;
            .price{
                height: 100%;
                width: 65%;
                padding: 0 25px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media (max-width: 380px) {
                    padding: 0 15px;
                }
                @media (max-width: 360px) {
                    padding: 0 10px;
                }
            }
            .submitBtn{
                width: 35%;
            }
        }
        .product{
            padding: 0 15px;
            @media (max-width: 420px) {
                padding: 0;
            }
            .price-item{
                font-family: "MS Pゴシック" ;
                margin: 15px 0;
            }
        }
        .title{
            color: #FFFFFF;
            font-size: 30px;
            padding: 6px 6px;
            font-weight: 600;
            letter-spacing: 0px;
            margin-bottom: 20px;
        }
        .cvbtn{
            background: #00A8A8;
            color: #FFFFFF;
            font-size: 15px;
            font-weight: 400;
            padding: 5px 18px;
            border-radius: 15px;
            font-weight: 500;
            letter-spacing: 0px;
            @media (max-width: 355px) {
                padding: 5px 10px;
            }
        }
        .btn{
            background: #C1C1C1;
            color: #323232;
            font-size: 15px;
            padding: 2px 10px;
            border-radius: 15px;
            font-weight: 600;
            margin-right: 5px;
            letter-spacing: 0px;
            margin: 15px 0;
        }
    }

</style>