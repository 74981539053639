<!-- item.vue -->

<template>
  <div class="home">
    <div class="inner">
      <div class="top">

        <div class="top-calendar">
          <div class="calendar">
            <button class="top--back" @click="getBackDate">
              <img src="../../assets/arowleft.png" alt="">
            </button>
            <label for="datepicker" class="top--datepicker">
              <input type="date" id="datepicker" v-model="todayDate">
            </label>
            <button class="top--next" @click="getNextDate">
              <img src="../../assets/arowright.png" alt="">
            </button>
          </div>
        </div>

        <!-- トリップボタン -->
        <trip-button class="trip" @updateDate="updateTodayDate" />

        <!-- 検索結果画面へ -->
        <button v-if="isLoggedIn" class="searched" @click="prisetSearch">
          <img src="../../assets/searched.png" alt="" class="searched-btn">
        </button>
        
        <!-- ソートボタン -->
        <div class="sortbox" ref="sortBoxRef" @click="toggleSortButtons">
          <button class="sort">
              <img src="../../assets/sort.png" alt="" class="sort-btn">
              <p id="text"></p>
          </button>
        </div>

        <div class="sort-buttons" ref="sortButtonsRef" :class="{ open: isSortButtonsOpen }" @click.stop>
          <button class="sort-button" @click="sort('sortALL', 'All')">
            <img src="../../assets/sorta.png" alt="" class="sort-btn">
          </button>
          <button class="sort-button" @click="sort('sortDAY', 'D')">
            <img src="../../assets/sortd.png" alt="" class="sort-btn">
          </button>
          <button class="sort-button" @click="sort('sortWEEK', 'W')">
            <img src="../../assets/sortw.png" alt="" class="sort-btn">
          </button>
          <button class="sort-button" @click="sort('sortMONTH', 'M')">
            <img src="../../assets/sortm.png" alt="" class="sort-btn">
          </button>
          <button class="sort-button" @click="sort('sortYEAR', 'Y')">
            <img src="../../assets/sorty.png" alt="" class="sort-btn">
          </button>
        </div>

        <div class="linebottom">
          <img src="../../assets/linebottom.png" alt="">
        </div>

      </div>

      <div class="list" ref="list" @scroll="handleScroll">
        <div v-if="isLoading" class="loading">
          <span class="loading-overlay"><i class="fa fa-spinner fa-pulse fa-3x fa-fw loading-image"></i></span>
          <span class="loadingText">Loading...</span>
        </div>
        <div v-else :class="{ 'no-posts': sortedWhispers.length === 0 }">
          <div v-if="sortedWhispers.length === 0">
            <div class="date">
              「{{formattedTodayDate}}」に<br>投稿記事はありません
            </div>
            <p class="word">
              ブロックワードにより投稿記事が表示されてない場合があります。<br>
              <a href="/blockword">ブロックワード設定</a>より確認してください。
            </p>
          </div>
          <div v-else>
              <div v-for="(whisper, index) in sortedWhispers" :key="whisper.id" class="item" :class="{ 'relative': whisper.isShowSelectButton }">

                  <router-link :to="'/postdetail/'+whisper.id" class="post">
                      <!-- <div class="content post--title" v-html="whisper.title"></div> -->
                      <div class="content post--title">
                          <h1 v-text="whisper.title"></h1>
                          <!-- <p>{{ index + 1 }}</p> -->
                          <p :class="`index-color-${index}`">{{ index + 1 }}</p>
                      </div>
                      <div class="content post--lead" v-if="whisper.lead" v-html="whisper.lead"></div>
                      <div class="content post--body" v-if="!whisper.lead" v-html="whisper.body"></div>
                  </router-link>

                  <div v-if="whisper.imageUrls && whisper.imageUrls.length > 0" class="content post--img">
                    <ul class="post--imgbox" :class="`images-${whisper.imageUrls.length}`">
                      <li v-for="(image, index) in whisper.imageUrls" :key="index" class="post--imgpin" @click="openimgModal(image)">
                        <img :src="image" alt="Selected Image" class="post--imgsize">
                      </li>
                    </ul>
                  </div>

                  <div class="modal imgModal">
                    <div class="imgModal-content">
                      <span class="close" @click="closeimgModal">&times;</span>
                      <img :src="selectedImage" alt="Modal Image" class="modal-image">
                    </div>
                  </div>


                  <div v-if="hasValidTags(whisper)" class="content post--tags">
                      <div class="content tagsbtn">
                          <span>タグ</span>
                          <router-link v-for="(tag, tagIndex) in whisper.tags" :key="tagIndex" :to="'/search/' + tag" @click="search(tag)">{{ tag }}</router-link>
                      </div>
                  </div>

                  <div class="post--status">
                      <div>
                        <router-link :to="'/postdetail/'+whisper.id" class="post--status--days" v-if="formatDate(whisper.dataUpdated) !== formatDate(whisper.dataCreated)">
                          <img src="../../assets/edit.png" alt="" class="smallicon todays">
                          <div class="content post--days" v-html="formatDate(whisper.dataUpdated)"></div>
                        </router-link>
                        <router-link :to="'/postdetail/'+whisper.id" class="post--status--days">
                          <img src="../../assets/timeing.png" alt="" class="smallicon">
                          <div class="content post--days" v-html="formatDate(whisper.dataCreated)"></div>
                        </router-link>
                      </div>
                      <!-- ルクス付与 -->
                      <button class="point-button" @click="openLxModal(whisper)">
                        <img src="../../assets/LX.png" alt="" class="smallicon" />
                        <span v-html="whisper.point"></span>
                      </button>

                      <!-- ルクス付与モーダル -->
                      <LxModal
                        :isOpen="whisper.isModalOpen" :whisper="whisper" :currentPoint="currentPoint" positionClass="fixed"
                        @close="closeLxModal(whisper)"
                      />
                      
                      <!-- コメント追加 -->
                      <commentComp :id="whisper.id" />
                      
                      <!-- お気に入り -->
                      <button @click="togglemylist(whisper.id, whisper.originid)">
                          <img v-if="isFavorite(whisper.id)" src="../../assets/favoriteon.png" alt="お気に入りon" class="smallicon">
                          <img v-else src="../../assets/favoriteoff.png" alt="お気に入りoff" class="smallicon">
                      </button>

                      <!-- SNS共有 -->
                      <button @click="openShareModal(whisper.id)">
                        <img src="../../assets/share.png" alt="共有" class="smallicon">
                      </button>

                      <div v-if="whisper.isShowSelectButton" class="item-mask"></div>

                      <div class="setting">
                          <button v-if="isLoggedIn && whisper.originid === currentUserOriginid" class="setting-button" @click="SettingButton(whisper)" @click.stop>
                             ･･･
                          </button>

                          <div class="selectbutton" v-show="whisper.isShowSelectButton" @click.stop>
                              <button class="rembicon" @click="deletepost(whisper.id)">削除</button>
                              <button class="editicon" @click="editpost(whisper)">編集</button>
                          </div>
                      </div>

                  </div>

              </div>
          </div>
        </div>
      </div>


    </div>
  </div>

  <EditModal :isPostModalOpen="isPostModalOpen" :whisper="selectedWhispe" @update:isPostModalOpen="updatePostModalState" />

  <!-- SNS共有用 コンポーネントの追加 -->
  <ShareModal :isOpen="shareModalOpen" :shareUrl="shareUrl" @close="closeShareModal"/>
</template>



<script>
  import TripButton from '@/components/itemComponents/tripDateButton.vue';
  // import TripButton from '@/components/itemComponents/sortDateButton.vue';
  import commentComp from '@/components/itemComponents/comment.vue';
  // import pointComp from '@/components/itemComponents/pointadd.vue';
  import EditModal from "../itemComponents/editpost.vue";
  import ShareModal from '../itemComponents/shareModal.vue';
  import LxModal from '../itemComponents/lxModal.vue';
  import { ref, computed, onMounted, onBeforeUnmount, watchEffect, watch } from 'vue';
  import { db } from '../../main';
  import { increment, query, getDocs, collection, where, orderBy, doc, updateDoc, getDoc, limit, startAfter, setDoc, deleteDoc, serverTimestamp  } from 'firebase/firestore';
  import { format, addDays, subDays } from 'date-fns';
  import { onAuthStateChanged } from 'firebase/auth';
  import { useStore } from 'vuex';
  import { auth } from '../../main';
  import { useRouter } from 'vue-router';
  import debounce from 'lodash/debounce';

  export default {
    components: {
      commentComp,
      TripButton,
      EditModal,
      ShareModal,
      LxModal,
    },

    setup() {
      const todayDate = ref(format(new Date(), 'yyyy-MM-dd'));

      const texttodayDate = ref(new Date(todayDate.value));
      const formattedTodayDate = computed(() => {
        const date = texttodayDate.value;
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // 月を2桁に
        const dd = String(date.getDate()).padStart(2, '0'); // 日を2桁に
        return `${yyyy}/${mm}/${dd}`;
      });
      const store = useStore();
      const lastVisible = ref(null);
      const isUpdateButtonDisabled = ref(false);
      const currentUser = ref(null);
      const lastUpdateTimestamp = ref(null);
      const router = useRouter();
      const isPostModalOpen = ref(false);
      const selectedWhispe = ref(null);

      const Wordpriset = ref([]);

      const selectedImage = ref(null);

      const currentPoint = ref(0); // 現在の自分のポイント数を保持するプロパティ

      const shareModalOpen = ref(false);
      const shareUrl = ref('');

      const isLoading = ref(false);

      const formatDate = (timestamp) => {
          if (!timestamp) return ''; // デフォルト値を返す
          let date;
          if (timestamp.toDate) {
              // Firestore の Timestamp オブジェクト
              date = timestamp.toDate();
          } else if (timestamp instanceof Date) {
              // JavaScript の Date オブジェクト
              date = timestamp;
          } else {
              // その他の形式（例えば、文字列）
              date = new Date(timestamp);
          }

          if (isNaN(date.getTime())) {
              return ''; // 無効な日付の場合
          }

          try {
              return format(date, 'yyyy/M/d H:mm');
          } catch (error) {
              return 'フォーマットエラー';
          }
      };

      // タグが有効かどうかを判定するメソッド
      const hasValidTags = (whisper) => {
        return Array.isArray(whisper.tags) && whisper.tags.some(tag => tag.trim() !== '');
      };


      const sortedWhispers = computed(() => store.state.sortedWhispers);
      // ブロックワード
      const blockedWords = ref([]);

      // ソートボタンの表示状態を管理
      const isSortButtonsOpen = ref(false);
      const sortButtonsRef = ref(null);
      const sortBoxRef = ref(null); 

      const toggleSortButtons = (event) => {
        // すべての selectbutton を非表示にする
        sortedWhispers.value.forEach(whisper => {
          whisper.isShowSelectButton = false;
        });
        
        // ソートボタンの表示状態をトグル
        isSortButtonsOpen.value = !isSortButtonsOpen.value;
        
        event.stopPropagation(); // イベントのバブリングを停止
      };

      // グローバルクリックハンドラーの修正
      const handleDocumentClick = (event) => {
        // sortButtonsRef と sortBoxRef が設定されているかを確認
        const sortButtonsElement = sortButtonsRef.value;
        const sortBoxElement = sortBoxRef.value;

        if (
          isSortButtonsOpen.value && 
          sortButtonsElement && 
          sortBoxElement && 
          !sortButtonsElement.contains(event.target) && 
          !sortBoxElement.contains(event.target)
        ) {
          isSortButtonsOpen.value = false;
        }

        // すべての selectbutton を閉じる
        store.commit('closeAllSelectButtons');
      };

      onMounted(() => {
        document.addEventListener('click', handleDocumentClick);
      });

      onBeforeUnmount(() => {
        document.removeEventListener('click', handleDocumentClick);
      });

      // todayDateの変更を監視し、変更があった場合にログを表示する
      watch(todayDate, (newDate, oldDate) => {
        //console.log(`todayDate changed from ${oldDate} to ${newDate}`);

        // セッションストレージに選択した日付を追加または更新
        sessionStorage.setItem('selectDAY', newDate);
        texttodayDate.value = new Date(newDate.replace(/-/g, '/')); // texttodayDateを更新

        // ローカルストレージから sort の値を取得
        const sortValue = localStorage.getItem('sort');

        // sort の値が ALL の場合にのみ fetchWhispers を発火
        if (sortValue === 'sortALL') {
          fetchWhispers();
        }
      });

      const isLoggedIn = ref(false); // ログイン状態を追跡
      const currentUserOriginid = ref('');

      // 認証状態を監視
      onMounted(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                isLoggedIn.value = true;
                currentUser.value = user.uid;

                try {
                    const userDocRef = doc(db, 'users', user.uid);
                    const userDocSnap = await getDoc(userDocRef);
                    if (userDocSnap.exists()) {
                        currentUserOriginid.value = userDocSnap.data().originid;
                    }
                } catch (error) {
                    //console.log('ユーザーのoriginid取得中にエラーが発生しました:', error);
                }
            } else {
                isLoggedIn.value = false;
                currentUser.value = null;
                currentUserOriginid.value = '';
            }
        });
    });

      const confirmLogin = () => {
        if (!isLoggedIn.value) {
          if (confirm("ログインしてください")) {
            router.push('/login');
          }
          return false; // 未ログインの場合はfalseを返す
        }
        return true; // ログインしている場合はtrueを返す
      };

      const search = (tag) => {
          const searchParams = [];

          searchParams.push(tag);

          // ローカルストレージにsearchParamsを保存する
          localStorage.setItem('searchParams', JSON.stringify(searchParams));
          // ローカルストレージの既存のsearchTextParamsを削除する
          localStorage.removeItem('searchTextParams');
          // 新しい内容を保存する
          localStorage.setItem('searchTextParams', JSON.stringify(searchParams));
      };

      // ブロックワードを取得する関数
      const fetchBlockedWords = async () => {
          // ユーザーがログインしているか確認
          if (!auth.currentUser) {
              blockedWords.value = []; // ブロックワードをクリア
              return; // 関数を終了
          }

          // ユーザーがログインしている場合
          const userId = auth.currentUser.uid;
          const userBlocksCollection = collection(db, 'users', userId, 'blocks');

          try {
            const querySnapshot = await getDocs(userBlocksCollection);

            blockedWords.value = [];

            querySnapshot.forEach((doc) => {
              const blockedWord = doc.data().text; // データ構造に基づいて適切に調整してください
              blockedWords.value.push(blockedWord);
            });
          } catch (error) {
            alert('データの取得に失敗しました。しばらく時間をおいてから再度お試しください。');
          }
      };

      // ヘルパー関数: 配列を指定されたバッチサイズに分割
      const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
          result.push(array.slice(i, i + size));
        }
        return result;
      };

      // ヘルパー関数: 並列で処理を実行（バッチサイズを制限）
      const asyncPool = async (poolLimit, array, iteratorFn) => {
        const ret = [];
        const executing = [];
        for (const item of array) {
          const p = Promise.resolve().then(() => iteratorFn(item));
          ret.push(p);

          if (poolLimit <= array.length) {
            const e = p.then(() => executing.splice(executing.indexOf(e), 1));
            executing.push(e);
            if (executing.length >= poolLimit) {
              await Promise.race(executing);
            }
          }
        }
        return Promise.all(ret);
      };

      const fetchWhispers = async () => {
        isLoading.value = true; // ローディング開始
        try {
          await fetchBlockedWords();

          const whispersCollection = collection(db, 'whispers');
          const whispersQuery = query(
            whispersCollection,
            where('setdays', '==', todayDate.value),
            orderBy('point', 'desc'),
            limit(100)
          );

          const querySnapshot = await getDocs(whispersQuery);

          const fetchedWhispers = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            isShowSelectButton: false, // 各whisperの初期状態を設定
            isAddingPoint: false,      // ポイント追加の初期状態を設定
            ...doc.data()
          }));

          // ブロックワードを含むWhispersをフィルタリング
          const filteredWhispers = fetchedWhispers.filter((whisper) => {
            const isBlocked = blockedWords.value.some(word => {
              return (
                whisper.title.includes(word) ||
                (whisper.lead && whisper.lead.includes(word)) ||
                whisper.body.includes(word)
              );
            });
            return !isBlocked;
          });

          // sortの判定
          const sortValue = localStorage.getItem('sort') || 'sortALL';

          if (sortValue === 'sortALL') {
            store.commit('setSortedWhispers', filteredWhispers);
          } else if (['sortDAY', 'sortWEEK', 'sortMONTH', 'sortYEAR'].includes(sortValue)) {

            let pointLogs = [];
            let datesToFetch = [];

            const today = new Date();

            if (sortValue === 'sortDAY') {
              // 現在の時間から過去24時間分のデータを取得
              for (let i = 0; i < 24; i++) {
                const dateTime = new Date(today);
                dateTime.setHours(today.getHours() - i);
                const formattedDateTime = `${dateTime.getFullYear()}${String(dateTime.getMonth() + 1).padStart(2, '0')}${String(dateTime.getDate()).padStart(2, '0')}${String(dateTime.getHours()).padStart(2, '0')}`;
                datesToFetch.push(formattedDateTime);
              }
            } else if (sortValue === 'sortWEEK') {
              // 一週間分の日付を取得
              for (let i = 0; i < 7; i++) {
                const date = new Date(today);
                date.setDate(today.getDate() - i);
                const formattedDateForDoc = date.toISOString().split('T')[0].replace(/-/g, '');
                datesToFetch.push(formattedDateForDoc);
              }
            } else if (sortValue === 'sortMONTH') {
              // 一ヶ月分の日付を取得
              const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
              for (let i = 0; i < daysInMonth; i++) {
                const date = new Date(today);
                date.setDate(today.getDate() - i);
                const formattedDateForDoc = date.toISOString().split('T')[0].replace(/-/g, '');
                datesToFetch.push(formattedDateForDoc);
              }
            } else if (sortValue === 'sortYEAR') {
              // 一年間分の日付を取得
              for (let i = 0; i < 365; i++) {
                const date = new Date(today);
                date.setDate(today.getDate() - i);
                const formattedDateForDoc = date.toISOString().split('T')[0].replace(/-/g, '');
                datesToFetch.push(formattedDateForDoc);
              }
            }

            // クエリ関数の作成
            const fetchPointLogs = async (formattedDate) => {
              const collectionName = sortValue === 'sortDAY' ? 'pointLogDay' : 'pointLog';
              const pointLogRef = collection(db, collectionName, formattedDate, 'whisperPoints');
              const pointLogSnapshot = await getDocs(pointLogRef);
              return pointLogSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
              }));
            };

            // 並列クエリの実行（同時に実行するクエリの数を制限）
            const poolLimit = 10; // 同時に実行するクエリの数（適宜調整）
            const chunks = chunkArray(datesToFetch, poolLimit);

            for (const chunk of chunks) {
              const results = await Promise.all(chunk.map(fetchPointLogs));
              results.forEach(pointLogsBatch => {
                pointLogsBatch.forEach(pointLog => {
                  const existingLog = pointLogs.find(log => log.id === pointLog.id);
                  if (existingLog) {
                    existingLog.pointsAdded += pointLog.pointsAdded;
                  } else {
                    pointLogs.push(pointLog);
                  }
                });
              });
            }

            // console.log(`${sortValue} から取得したドキュメント`, pointLogs);

            // filteredWhispersとpointLogsのIDを比較して一致するものをrankingArrayに追加し、pointsAddedの高い順にソート
            const rankingArray = filteredWhispers
              .filter(whisper => pointLogs.some(pointLog => pointLog.id === whisper.id))
              .map(whisper => {
                const matchedPointLog = pointLogs.find(pointLog => pointLog.id === whisper.id);
                return {
                  ...whisper,
                  pointsAdded: matchedPointLog ? matchedPointLog.pointsAdded : 0
                };
              })
              .sort((a, b) => b.pointsAdded - a.pointsAdded); // pointsAddedの高い順にソート

            // ポイントが追加されていないWhispersを追加
            const noPointsAddedWhispers = filteredWhispers.filter(whisper => !pointLogs.some(pointLog => pointLog.id === whisper.id));

            const finalRankingArray = [...rankingArray, ...noPointsAddedWhispers];

            store.commit('setSortedWhispers', finalRankingArray);

          } else {
            // ソート済みWhispersをストアにコミット
            store.commit('setSortedWhispers', filteredWhispers);
          }

          // 最後に取得したドキュメントをlastVisibleに設定
          lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1];
        } catch (error) {
          alert('データの取得に失敗しました。しばらく時間をおいてから再度お試しください。');
        } finally {
          isLoading.value = false;
        }
      };

      const Addpostlink = async (whisperId) => {
        try {
          const userId = auth.currentUser.uid;
          const userDocRef = doc(db, 'users', userId);
          const userDoc = await getDoc(userDocRef);
          const userPostsCollectionRef = collection(db, 'users', userId, 'addpointPost');

          // 投稿のドキュメント ID を追加
          await setDoc(doc(userPostsCollectionRef, whisperId), {});

          //console.log('投稿リンクが追加されました');
        } catch (error) {
          //console.log('投稿リンクの追加中にエラーが発生しました:', error);
        }
      };

      const SettingButton = async (whisper) => {
        // ソートボタンが開いていれば閉じる
        if (isSortButtonsOpen.value) {
          isSortButtonsOpen.value = false;
        }

        const userId = auth.currentUser.uid;
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);

        const userOriginid = userDoc.data().originid;

        if (whisper.originid === userOriginid) {
          // すべてのselectbuttonを閉じる
          store.commit('closeAllSelectButtons');
          // 対象のwhisperのselectbuttonをトグル
          const whisperIndex = store.state.sortedWhispers.findIndex(w => w.id === whisper.id);
          if (whisperIndex !== -1) {
            const currentValue = store.state.sortedWhispers[whisperIndex].isShowSelectButton;
            // 新しい状態を設定
            store.state.sortedWhispers[whisperIndex].isShowSelectButton = !currentValue;
            // sessionStorage を更新
            sessionStorage.setItem('sortedWhispers', JSON.stringify(store.state.sortedWhispers));
          }
        } else {
          // 遷移処理
          router.push('/postdetail/' + whisper.id);
        }
      };

      const userMypoint = async () => {
        try {
          const userId = auth.currentUser.uid;  // user パラメータを使用する

          // Firestoreからユーザードキュメントの取得
          const userDocRef = doc(db, 'users', userId);
          const userDoc = await getDoc(userDocRef);

          // ポイントを取得
          const userPoint = userDoc.data().point || 0;

          // currentPoint にセット
          currentPoint.value = userPoint;

          //console.log('currentPoint.value', currentPoint.value);

          //console.log('ユーザーのmylistを取得し、ストアに追加しました');
        } catch (error) {
          //console.log('ユーザーのmylistを取得中にエラーが発生しました:', error);
        }
      }

      const userMylist = async () => {
        try {
          if (!auth.currentUser) {
            //console.log('ユーザーがログインしていません');
            return;
          }
          const userId = auth.currentUser.uid;
          // Firestoreからユーザードキュメントのmylistサブコレクションを取得
          const mylistCollectionRef = collection(db, 'users', userId, 'myfavoritePost');
          const mylistQuerySnapshot = await getDocs(mylistCollectionRef);

          // ユーザーのmylist配列を初期化
          const mylist = [];

          // mylistサブコレクション内のドキュメントを配列に追加
          mylistQuerySnapshot.forEach((doc) => {
            mylist.push(doc.id);
          });

          // 取得した mylist を Vuex ストアに追加
          store.commit('setUserMylist', mylist);

          //console.log('ユーザーのmylistを取得し、ストアに追加しました');
        } catch (error) {
          //console.log('ユーザーのmylistを取得中にエラーが発生しました:', error);
        }
      };

      const getNextDate = () => {
          try {
              const nextDate = addDays(new Date(todayDate.value), 1);
              todayDate.value = format(nextDate, 'yyyy-MM-dd');
              // セッションストレージに選択した日付を追加または更新
              sessionStorage.setItem('selectDAY', todayDate.value);
              fetchWhispers();
          } catch (error) {
              if (error instanceof RangeError && error.message.includes('Invalid time value')) {
                  //console.error('Invalid time value error occurred:', error);
                  location.reload(); // サイトをリロード
              } else {
                  throw error; // 他のエラーは再スロー
              }
          }
      };

      const getBackDate = () => {
          try {
              const prevDate = subDays(new Date(todayDate.value), 1);
              todayDate.value = format(prevDate, 'yyyy-MM-dd');
              // セッションストレージに選択した日付を追加または更新
              sessionStorage.setItem('selectDAY', todayDate.value);
              fetchWhispers();
          } catch (error) {
              if (error instanceof RangeError && error.message.includes('Invalid time value')) {
                  //console.error('Invalid time value error occurred:', error);
                  location.reload(); // サイトをリロード
              } else {
                  throw error; // 他のエラーは再スロー
              }
          }
      };


      const updateTodayDate = (newDate) => {
          if (isUpdateButtonDisabled.value) {
              const elapsedSeconds = Math.floor((Date.now() - lastUpdateTimestamp.value) / 1000);
              const remainingSeconds = 3 - elapsedSeconds; // 30を3に変更

              if (remainingSeconds > 0) {
                  alert(`あと${remainingSeconds}秒でトリップボタンが使用可能です。`);
                  return;
              }
          }

          isUpdateButtonDisabled.value = true;
          //console.log("変更前の日付", todayDate.value);
          let formattedNewDate = format(new Date(newDate), 'yyyy-MM-dd');

          // 新しい日付が現在の日付と同じ場合、もう一度新しい日付を取得
          while (formattedNewDate === todayDate.value) {
              // 新しい日付を再取得
              formattedNewDate = format(new Date(), 'yyyy-MM-dd');
          }

          //console.log("変更後の日付", todayDate.value);

          todayDate.value = formattedNewDate;
          fetchWhispers();

          lastUpdateTimestamp.value = Date.now();

          setTimeout(() => {
              isUpdateButtonDisabled.value = false;
          }, 3000); // 30000を3000に変更
      };


      const togglemylist = async (whisperId,whisperOriginid) => {
        //console.log('whisperId:', whisperId);
        //console.log('whisperId:', whisperOriginid);
        // ユーザーがログインしているか確認
        if (!confirmLogin()) {
          return; // 未ログインの場合は処理を中断
        }

        try {
          const userId = auth.currentUser.uid;

          // Firestoreからユーザードキュメントを取得
          const userDocRef = doc(db, 'users', userId, 'myfavoritePost', whisperId);
          const userDoc = await getDoc(userDocRef);

          // 今日の日付を取得してフォーマット
          const today = new Date();
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0');
          const day = String(today.getDate()).padStart(2, '0');
          const formattedDate = `${year}${month}${day}`;

          // favoriteLogコレクション内の今日の日付のドキュメント参照を作成
          const commentLogDocRef = doc(db, 'favoriteLog', formattedDate);
          const wisperFavoriteCollectionRef = collection(commentLogDocRef, 'Wisperfavorite');
          const newCommentDocRef = doc(wisperFavoriteCollectionRef, whisperId);

          if (userDoc.exists()) {
            // ドキュメントが存在する場合、削除
            await deleteDoc(userDocRef);
            //console.log('whisperIdがmylist内に存在するため削除しました');

            // Wisperfavorite内の該当するドキュメントのpointsAddedから1ポイント引く
            const newCommentDocSnap = await getDoc(newCommentDocRef);
            if (newCommentDocSnap.exists() && newCommentDocSnap.data().pointsAdded > 0) {
              await updateDoc(newCommentDocRef, {
                pointsAdded: newCommentDocSnap.data().pointsAdded - 1
              });
              //console.log('Wisperfavoriteのポイントが1ポイント引かれました');
            }
          } else {
            // ドキュメントが存在しない場合、作成
            await setDoc(userDocRef, {});
            //console.log('whisperIdがmylist内に存在しないため作成しました');

            // Wisperfavorite内にデータを追加または更新
            const newCommentDocSnap = await getDoc(newCommentDocRef);
            if (newCommentDocSnap.exists()) {
              // ドキュメントが存在する場合、pointsAddedに1を加算
              await updateDoc(newCommentDocRef, {
                pointsAdded: newCommentDocSnap.data().pointsAdded + 1
              });
              //console.log('Wisperfavoriteのポイントが更新されました');
            } else {
              // ドキュメントが存在しない場合、新規作成
              await setDoc(newCommentDocRef, {
                pointsAdded: 1,
                originid: whisperOriginid
              });
              //console.log('Wisperfavoriteにポイントとoriginidが追加されました');
            }
          }

          // ユーザーのmylistを再取得
          userMylist();

          //console.log('ユーザーのmylistが正常に更新されました');
        } catch (error) {
          //console.log('ユーザーのmylistを更新中にエラーが発生しました:', error);
        }
      };

      const isFavorite = (whisperId) => {
        return store.state.userMylist.includes(whisperId);
      };

      let isScrollHandling = false;

      const handleScroll = debounce(async () => {
        const list = document.querySelector('.list');
        const isAtBottom = list.scrollHeight - list.scrollTop <= list.clientHeight + 10; // 誤差を少し許容

        // スクロール時にすべてのselectbuttonを非表示にする
        store.commit('closeAllSelectButtons');

        if (isAtBottom && !isScrollHandling) {
          isScrollHandling = true;

          try {
            await loadNextPage();
          } catch (error) {
            alert('データの取得に失敗しました。しばらく時間をおいてから再度お試しください。');
          } finally {
            // 一定時間後に再び呼び出し可能にする
            setTimeout(() => {
              isScrollHandling = false;
            }, 600000); // 600000ミリ秒（10分）後に再びスクロールイベントを許可
          }
        }
      }, 0);

      const loadNextPage = async () => {
        try {
          const whispersCollection = collection(db, 'whispers');
          const querySnapshot = await getDocs(
            query(
              whispersCollection,
              where('setdays', '==', todayDate.value),
              orderBy('point', 'desc'),
              startAfter(lastVisible.value),
              limit(100)
            )
          );

          const fetchedWhispers = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

          // もしもっとドキュメントがなければ、読み込みを停止
          if (querySnapshot.docs.length === 0) {
            //console.log('読み込むWhisperがもうありません。');
            return;
          }

          // 新しいWhisperをフィルタリングしてsortedWhispersに追加
          const newWhispers = fetchedWhispers.filter((whisper) => {
            const isBlocked = blockedWords.value.some(word => whisper.body.includes(word));
            return !isBlocked;
          });

          // Vuex ストアに新しいWhispersを追加
          store.commit('addSortedWhispers', newWhispers);

          // 次のクエリのためにlastVisibleを更新
          lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1];
        } catch (error) {
          //console.log('次のページを読み込む際にエラーが発生しました:', error);
        }
        //console.log("loadNextPage 関数が完了しました");
      };

      onAuthStateChanged(auth, (user) => {
        // ユーザーのログイン状態が変更された時のコールバック
        //console.log('動く');
        const authLog = localStorage.getItem('authlog');

        // authlogがtrueであるかをチェック
        if (authLog === 'true') {
          fetchWhispers();
        }
      });

      const openLxModal = (whisper) => {
        if (!confirmLogin()) return; // 未ログインの場合は処理を中断

        userMypoint(); // ユーザー所持ポイント取得

        whisper.isModalOpen = !whisper.isModalOpen; // 開閉フラグ
      };

      // ルクス付与モーダルを閉じる
      const closeLxModal = (whisper) => {
        whisper.isModalOpen = false;
      };

      const deletepost = async (whisperId) => {
        // アラートを表示し、ユーザーが確認するまで処理を中断する
        const confirmDelete = window.confirm("削除しますか？");
        if (!confirmDelete) {
          return;
        }
        try {
          // 削除前に投稿のデータを取得
          const docRef = doc(db, 'whispers', whisperId);
          const whisperDoc = await getDoc(docRef);
          const originid = whisperDoc.data().originid;

          // 現在のユーザーの originid を取得
          const userId = auth.currentUser.uid;
          const userDocRef = doc(db, 'users', userId);
          const userDoc = await getDoc(userDocRef);
          const userOriginid = userDoc.data().originid;

          // originid が異なる場合は削除しない
          if (originid !== userOriginid) {
            return;
          }

          // 削除処理を実行
          await deleteDoc(docRef);

          //console.log("投稿を削除しました");

          // 投稿を削除した後にサイトをリロード
          window.location.reload();
        } catch (error) {
          //console.log("投稿の削除中にエラーが発生しました:", error);
        }
      };

      const editpost = async (whisper) => {
        if (currentUser.value) {
          //console.log('編集だぜ',whisper);
          isPostModalOpen.value = true;
          selectedWhispe.value = whisper
        } else {
          //console.log("ユーザーがログインしていません。モーダルを開くことができません。");
        }
      }

      const updatePostModalState = (newState) => {
          isPostModalOpen.value = newState;
      };

      const prisetSearch = () => {
        const wordPrisetFromStorage = localStorage.getItem('WordPriset');
        if (wordPrisetFromStorage) {
          const wordPrisetArray = JSON.parse(wordPrisetFromStorage);

          if (wordPrisetArray.length === 0) {
            router.push('/searchpriset/');
          } else {
            localStorage.removeItem('searchTextParams');
            localStorage.setItem('searchTextParams', JSON.stringify(wordPrisetArray));
            router.push('/search/' + wordPrisetArray);
          }
        }
      };

      const openimgModal = (image) => {
        const imgModal = document.querySelector('.imgModal');
        if (imgModal) {
            imgModal.classList.add('open');
            selectedImage.value = image;
        }
      };

      const closeimgModal = () => {
        const imgModal = document.querySelector('.imgModal');
          if (imgModal) {
              imgModal.classList.remove('open');
              selectedImage.value = null;
          }
      };

      // SNS共有
      const openShareModal = (whisperId) => {
        const currentDomain = window.location.origin;
        shareUrl.value = `${currentDomain}/postdetail/${whisperId}`;
        shareModalOpen.value = true;
      };
      const closeShareModal = () => {
        shareModalOpen.value = false;
      };

      onMounted(async () => {
        auth.onAuthStateChanged(async user => {
          currentUser.value = user;
          userMylist();
          if (user) {
            const uid = user.uid;

            // 支払い情報が存在するか確認
            const paymentsCollectionRef = collection(db, 'customers', uid, 'payments');
            const paymentsQuerySnapshot = await getDocs(paymentsCollectionRef);
            if (!paymentsQuerySnapshot.empty) {
              // ポイントの更新処理を呼び出す
              await updatePoint(uid);
            } else {
              //console.log("ポイント更新はない！！！！！！！！！！")
            }

            const userDocRef = doc(db, 'users', uid);
            const wordPrisetCollectionRef = collection(userDocRef, 'WordPriset');
            try {
              const querySnapshot = await getDocs(wordPrisetCollectionRef);
              querySnapshot.forEach(doc => {
                // ドキュメントの内容を取得し、localhostにセットするなどの処理を行う
                Wordpriset.value.push(doc.data().text);
              });
              // 取得したデータでローカルストレージを更新
              localStorage.setItem('WordPriset', JSON.stringify(Wordpriset.value));
              //console.log('Wordpriset内容', Wordpriset.value);
            } catch (error) {
              //console.error('Error getting documents: ', error);
            }
          }
          // セッションストレージのselectDAYの値を確認し、todayDateに設定
          const selectDAY = sessionStorage.getItem('selectDAY');
          if (selectDAY) {
            todayDate.value = selectDAY;
          } else {
            // todayDateの値をセッションストレージに設定
            sessionStorage.setItem('selectDAY', todayDate.value);
          }
        });

        // ローカルストレージから sort の値を取得
        let sortValue = localStorage.getItem('sort');

        // sort の値が ALL 以外の場合、ALL に変更
        if (sortValue !== 'sortALL') {
          sortValue = 'sortALL';
          localStorage.setItem('sort', 'sortALL');
        }

        // ボタンテキストを設定する関数
        const setButtonText = (text) => {
          const sortButton = document.querySelector('#text');
          sortButton.textContent = text;
        };

        // sortValue の値に応じてボタンテキストを設定
        switch (sortValue) {
          case 'sortALL':
            setButtonText('All');
            break;
          case 'sortDAY':
            setButtonText('D');
            break;
          case 'sortWEEK':
            setButtonText('W');
            break;
          case 'sortMONTH':
            setButtonText('M');
            break;
          case 'sortYEAR':
            setButtonText('Y');
            break;
          default:
            // デフォルトのテキストを設定する場合はここに追加
            break;
        }

      });


      const updatePoint = async (uid) => {
        // 支払い情報を取得しポイントに加算する処理
        const paymentsCollectionRef = collection(db, 'customers', uid, 'payments');
        const paymentsQuerySnapshot = await getDocs(paymentsCollectionRef);
        let totalAmount = 0;

        // 支払い情報をループして合計額を計算
        paymentsQuerySnapshot.forEach(doc => {
          totalAmount += doc.data().amount;
        });

        // ポイントに加算（×7）
        const pointsToAdd = totalAmount * 7;
        const userDocRef = doc(db, 'users', uid);
        await updateDoc(userDocRef, {
          point: increment(pointsToAdd)
        });

        // 支払い情報を削除
        const deletePromises = [];
        paymentsQuerySnapshot.forEach(doc => {
          deletePromises.push(deleteDoc(doc.ref));
        });

        await Promise.all(deletePromises);

        // ポイント追加後のアラートとリロード
        alert(`${pointsToAdd}ポイントを追加しました！`);
        window.location.reload();
      };


      const sort = (name, buttonText) => {
        if (buttonText == 'None') {
              localStorage.setItem('sort', '');
        } else {
            localStorage.setItem('sort', name);
        }
        // fetchWhispers 関数の前に、ボタンのテキストを設定する
        const sortButton = document.querySelector('#text');
        sortButton.textContent = buttonText;
        const sortButtons = document.querySelector('.sort-buttons');
        sortButtons.classList.remove('open');

        fetchWhispers();
      };

      return {
        isSortButtonsOpen,
        sortButtonsRef,
        sortBoxRef,
        toggleSortButtons,
        updatePoint,
        formattedTodayDate,
        texttodayDate,
        formatDate,
        selectedImage,
        openimgModal,
        closeimgModal,
        prisetSearch,
        Wordpriset,
        sort,
        sortedWhispers,
        todayDate,
        currentPoint,
        router,
        lastUpdateTimestamp,
        getNextDate,
        getBackDate,
        updateTodayDate,
        togglemylist,
        userMylist,
        isFavorite,
        handleScroll,
        loadNextPage,
        SettingButton,
        deletepost,
        editpost,
        isPostModalOpen,
        updatePostModalState,
        selectedWhispe,
        Addpostlink,
        search,
        isLoggedIn,
        currentUserOriginid,
        hasValidTags,
        isLoading,

        // ルクス付与
        openLxModal,
        closeLxModal,

        // SNS共有
        shareModalOpen,
        shareUrl,
        openShareModal,
        closeShareModal
      };

  },
};

</script>





<style lang="scss" scoped>

  /* 日付記事一覧ページ */
  .home {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;

    .inner {
      width: 600px;
      border-left: 5px solid #10AE33;
      border-right: 5px solid #10AE33;
      
      @media (max-width: 599px) {
        width: 100%;
        border-left: none;
        border-right: none;
      }
    }

    /* ヘッダー */
    .top {
      position: fixed;
      display: flex;
      height: 160px;
      width: 590px;
      z-index: 100;
      background: linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
      
      @media (max-width: 599px) {
        width: 100%;
        min-width: 320px;
        height: 140px;
      }

      /* 日付 */
      .top-calendar {
        position: absolute;
        top: -4%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 130px;
        width: 590px;
        color: #fff;
        
        @media (max-width: 599px) {
          width: 100%;
          height: 90px;
        }

        /* 日付エリア */
        .calendar {
          text-align: center;
          width: 65%;
          
          @media (max-width: 599px) {
            width: 100%;
          }

          /* 日付 戻る・進むボタン */
          .top--back, .top--next {
            margin: 0 1vw;
          }

          /* 日付欄 */
          .top--datepicker{
            font-weight: bold;
            font-size: 32px;
            font-family: "Noto Sans JP", sans-serif;
            @media (max-width: 599px) {
              font-size: 40px;
            }
            @media (max-width: 370px) {
              font-size: 35px;
            }
          }

          #datepicker{
            background-image: url("../../assets/calendar.png");
            background-repeat: no-repeat;
            background-position: right 10px center;
            cursor: pointer;
            -webkit-appearance: none;
            appearance: none;
            background-size: 20px;
            text-align: left;
            margin-top: 10px;
            padding: 0 10px;
            width: 65%;
            @media (max-width: 599px) {
              width: 290px;
            }
            
            @media (max-width: 370px) {
              width: 240px;
            }

          }

          input[type="date"] {
            &::-webkit-calendar-picker-indicator {
              opacity: 0;
              width: 20px;
              height: 20px;
              right: 10px;
              cursor: pointer;
            }
          }
        }
      }

      /* フォロータグボタン */
      .searched {
        position: absolute;
        bottom: 7%;
        left: 10%;
        
        img {
          width: 50px;
        }

        @media (max-width: 370px) {
          bottom: 8%;
          
          img {
            width: 43px;
          }
        }
      }

      /* トリップボタン */
      .trip{
        position: absolute;
        bottom: 12%;
        right: 17%;

        @media (max-width: 599px) {
          margin-right: 65px;
          margin-bottom: 5px;
          bottom: 9%;
          right: 6%;
        }
      }
      
      /* ソートボタン */
      .sortbox {
        position: absolute;
        bottom: 12%;
        width: 57px;
        right: 3%;
        @media (max-width: 599px) {
          right: 6%;
        }

        .sort {
          position: relative;
          bottom: 22%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;

          @media (max-width: 599px) {
            bottom: 9%;
            left: 8%;
            right: 100%;
          }

          #text{
            color: #fff;
            font-size: 20px;
          }
        }
      }

      /* ソート選択ボタン */
      .sort-buttons {
        display: none;
        flex-direction: column;
        gap: 8px;
        position: absolute;
        bottom: -100%;
        right: 4%;
        @media (max-width: 599px) {
          right: 6%;
          bottom: -115%;
        }

        &.open {
          display: flex;
        }

        .sort-button {
          width: 54px;
          height: 28px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      /* ライン */
      .linebottom{
        position: absolute;
        top: 74%;
        left: 50%;
        transform: translate(-50%, -50%); 
        @media (max-width: 599px) {
          top: 74%;
          z-index: -1;
        }
        img{
          height: 77%;
          @media (max-width: 599px) {
            margin-top: -10px;
            width: 260px;
          }
          @media (max-width: 370px) {
            width: 220px;
            margin-top: -23px;
          }
        }
      }
    }

    /* 記事一覧 */
    .list {
      width: 590px;
      height: 100vh;
      padding: 160px 0 60px 0;
      overflow-y: scroll;
      color: #fff;
      background-color: #323232;

      @media (max-width: 599px) {
        width: 100%;
        padding: 140px 0 60px 0;
      }
    
      /* ローディング表示 */
      .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;

        .loading-overlay {
          color: #00A8A8;
          font-size: 13px;
        }

        .loadingText {
          margin-left: 10px;
          font-size: 20px;
        }
      }

      /* 投稿記事がない場合 */
      .no-posts {
        list-style: none;
        padding: 5px 15px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        background: linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
        text-align: center;
        height: 100%;
        padding-top: 30px;

        .date {
          font-size: 36px;

          @media (max-width: 599px) {
            font-size: 32px;
          }
        }

        .word {
          letter-spacing: 2.5px;
          line-height: 1.5;

          a {
            color: #00a8a8;
          }
        }
      }

      // スクロールバーを非表示にする
      &::-webkit-scrollbar {
        display: none;
      }

      /* スクロール時にスクロールバー表示 */
      &:hover,
      &:active,
      &:focus {
        &::-webkit-scrollbar {
          display: block;
          width: 0px; /* スクロールバーの幅 */
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #828f85;
          border-radius: 5px;
        }
      }

      /* 投稿記事 */
      .item {
        list-style: none;
        padding: 10px 15px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        background: linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;

        @media (max-width: 380px) {
          padding: 6px 15px;
        }

        &.relative {
          position: relative;
        }

        &:hover{
          background:#323232;
        }

        /* 1位の記事の枠 */
        &:nth-child(1) {
          box-shadow: inset 0 0 10px #00a8a8;
        }

        /* 記事タイトルエリア */
        .post {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          gap: 10px;
          position: relative;
          list-style: none;
          min-height: 50px;

          @media (max-width: 599px) {
            min-height: 40px;
          }
          @media (max-width: 380px) {
            min-height: 30px;
          }

          /* タイトル行 */
          &--title {
            display: flex;
            align-items: center;
            flex-direction: row;
            position: relative;
            height: auto;
            font-weight: 800;
            font-size: 16px;

            @media (max-width: 599px) {
              font-size: 15px;
            }
            @media (max-width: 380px) {
              font-size: 13px;
            }

            /* タイトル */
            h1 {
              z-index: 2;
              position: relative;
              line-height: 1.4;
              letter-spacing: 1px;
            }

            /* 順位 */
            p {
              position: absolute;
              top: 0;
              right: 10px;
              z-index: 0;
              font-size: 56px;
              font-weight: 400;
              color: #00A8A850;
              white-space: pre-wrap;
              word-wrap: break-word;
              line-break: strict;
              line-height: 1;
              letter-spacing: -7px;
              font-family: "Montserrat", sans-serif;
              font-weight: bold;
              z-index: 1;

              @media (max-width: 599px) {
                font-size: 50px;
              }
              @media (max-width: 380px) {
                font-size: 40px;
              }
            }

            /* 順位カラー */
            .index-color-0 { 
              /* 1位 */
              color: #A7AA0D80; 
            }
            .index-color-1 { 
              /* 2位 */
              color: #93A2A250; 
            }
            .index-color-2 { 
              /* 3位 */
              color: #C1671350; 
              
            }
          }

          /* 前書き・本文 */
          &--lead, &--body {
            height: auto;
            font-size: 14px;
            font-weight: 500;
            white-space: pre-wrap;
            line-break: strict;
            word-wrap: break-word;
            line-height: 1.3;
            letter-spacing: 2px;
            z-index: 2;

            @media (max-width: 599px) {
              font-size: 12px;
            }
          }

          /* 本文の場合表示文字数を制限 */
          &--body{
            overflow: hidden;
            display: -webkit-box;
            line-clamp: 4;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;

            /* 前書きが存在する場合、本文は非表示 */
            &:empty {
              display: none;
            }
          }

          /* 画像 */
          &--img {
            display: flex;
            justify-content: center;

            .post--imgbox {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              height: auto;
              border-radius: 15px;
              border: 3px solid #10AE33;
              overflow: hidden;
              background-color: #D9D9D9;
              box-sizing: border-box;
              padding: 0;
              margin: 0;
              gap: 0;

              @media (max-width: 599px) {
                border-radius: 10px;
              }

              /* 画像が1枚の場合・画像が3枚かつ3枚目の場合*/
              &.images-1 .post--imgpin,
              &.images-3 .post--imgpin:nth-child(3) {
                width: 100%;
              }

              /* 画像が2枚以上の場合 */
              &.images-2 .post--imgpin,
              &.images-3 .post--imgpin,
              &.images-4 .post--imgpin {
                width: 50%;
              }

              .post--imgpin {
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border: 1px solid black;
                background-color: #D9D9D9;
                transform: scale(1.01);
                box-sizing: border-box;
                margin: 0;
                padding: 0;
              }
              
              .post--imgsize {
                width: 100%;
                height: 200px;
                object-fit: cover;
                z-index: 0;

                @media (max-width: 399px) {
                  height: 150px;
                }
              }
            }
          }
        }

        /* 画像拡大モーダル */
        .imgModal {
          position: fixed;
          top: 0;
          left: 0;
          background-color: #000000;

          /* 画像 */
          .imgModal-content {
            position: relative;
            width: 100%;
            height: 100%;
            min-width: 320px;

            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          /* 閉じるボタン */
          .close {
            position: absolute;
            margin: 10px;
            padding: 0 7px;
            right: 0;
            font-size: 30px;
            cursor: pointer;
            background: #00a8a8;
            border-radius: 18px;
          }
        }

        /* タグ一覧 */
        .tagsbtn {
          /* タグ表示 */
          span {
            background: #00a8a8;
            color: #ffffff;
            font-size: 12px;
            padding: 2px 5px 2px 2px;
            border-radius: 0 15px 15px 0;
            font-weight: 400;
            letter-spacing: 0px;
            margin-right: 5px;
            line-height: 2;

            @media (max-width: 599px) {
              font-size: 10px;
              padding: 2px 5px 2px 2px;
              border-radius: 0 15px 15px 0;
            }
          }

          /* タグ */
          a {
            white-space: nowrap;
            background: #C1C1C1;
            color: #323232;
            font-size: 12px;
            padding: 2px 10px;
            border-radius: 15px;
            font-weight: 600;
            letter-spacing: 0px;
            margin-right: 5px;
            line-height: 2;

            @media (max-width: 599px) {
              font-size: 10px;
              padding: 2px 6px;
              border-radius: 15px;
            }
            &:hover {
              background: #81afaf;
              color: #006400;
            }

            &:empty {
              display: none;
            }
          }
        }

        /* 記事フッター */
        .post--status {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          white-space: nowrap;
          line-height: 2;

          @media (max-width: 599px) {
            font-size: 10px;
            line-height: 1;
          }

          &--days{
            display: flex;
            align-items: center;
            line-height: 1.8;

            .smallicon{
              width: 16px;
              margin-right: 5px;

              @media (max-width: 599px) {
                width: 14px;
              }
            }
          }

          button {
            display: flex;
            align-items: center;

            img {
              width: 16px;
              @media (max-width: 599px) {
                width: 14px;
              }
            }
            span{
              padding-left: 6px;
            }
          }

          .item-mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color:  #00000080; /* 半透明の黒 */
            z-index: 90;
          }

          /* ⋯ボタン */
          .setting {
            position: relative;

            /* 編集・削除ボタン */
            .selectbutton {
              .editicon, .rembicon {
                background: linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
                border-radius: 2px;
                border: 1px solid #00A8A8;
                width: 75px;
                padding: 5px 10px;
                display: block;
                line-height: 2;
                margin: 5px 0px;
                position: absolute;
                text-align: end;
                right: -2px;
                bottom: 20px;
                z-index: 110;

                @media (max-width: 599px) {
                  width: 65px;
                  padding: 3px 10px;
                  font-size: 12px;
                  bottom: 10px;
                }

                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 5px;
                  transform: translateY(-50%);
                  width: 20px;
                  height: 20px;

                  @media (max-width: 599px) {
                    width: 12px;
                    left: 8px;
                  }
                }
              }

              /* 編集ボタン */
              .editicon {
                bottom: 55px;

                @media (max-width: 599px) {
                  bottom: 40px;
                }

                &::before {
                  background: url("../../assets/editicon.png") no-repeat center center;
                  background-size: contain;
                }
              }

              /* 削除ボタン */
              .rembicon {
                &::before {
                  background: url("../../assets/rembicon.png") no-repeat center center;
                  background-size: contain;
                }
              }
            }
          }
          
        }
      }
    }
  }

  /* モーダル */
  .modal {
    display: none;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 200;

    &.open{
        display: flex;
    }
    .modal-content {
      background: linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
      color: #FFF;
      border-radius: 10px;
      border: 1px solid #00A8A8;
    }
  }
</style>